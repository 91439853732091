import React, { useEffect } from "react";
import { Provider, useDispatch, useSelector } from "react-redux";
import { SnackbarProvider } from "notistack";
import CssBaseline from "@material-ui/core/CssBaseline";
import MuiTheme from "./services/theme";
import PopUp from "./components/popup";
import { ThemeProvider } from "@material-ui/core/styles";
import { BrowserRouter } from "react-router-dom";
import GoogleAnalytics from "./GoogleAnalytics";
import { Content } from "./components/layout/Content/Content";
import { Route, Routes as Switch, useNavigate } from "react-router";
import { PAGES } from "./constants";
import { LogInPage } from "./pages";
import { store } from "./services/redux";
import { CustomLoader } from "./components/common/CustomLoader";
import { initialize } from "./services/redux/actions/app-actions";
import { SnackbarUtilsConfigurator } from "./components/common/helpers/snackbar";
import { DispatchComponent } from "./services/api/helpers";
import { ForgetPage } from "./pages/auth/forgetPassword";
import { RegisterPage } from "./pages/auth/register";
import "./assets/_base.scss";
import "react-lazy-load-image-component/src/effects/blur.css";
import "swiper/components/effect-coverflow/effect-coverflow.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import "swiper/components/scrollbar/scrollbar.scss";
import "swiper/swiper.scss";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
export const App = () => {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <DispatchComponent />
        <AppWrapped />
      </BrowserRouter>
    </Provider>
  );
};

const AppWrapped = () => {
  const dispatch = useDispatch();
  const { init } = useSelector((state) => state.appState);
  const history = useNavigate();

  useEffect(() => {
    if (window.ReactNativeWebView) {
      const meta = document.createElement("meta");
      meta.setAttribute("content", "initial-scale=1.0, maximum-scale=1.0");
      meta.setAttribute("name", "viewport");
      document.getElementsByTagName("head")[0].appendChild(meta);
    }
  }, []);

  useEffect(() => {
    dispatch(initialize(history));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  if (!init) return <CustomLoader />;
  return (
    <SnackbarProvider autoHideDuration={3000} maxSnack={3}>
      <SnackbarUtilsConfigurator />
      <ThemeProvider theme={MuiTheme}>
        <Switch>
          <Route path={PAGES.login} element={<LogInPage />} />
          <Route path={PAGES.register} element={<RegisterPage />} />
          <Route path={PAGES.forgetPassword} element={<ForgetPage />} />
          <Route path="*" element={<Content />} />
        </Switch>
        <PopUp />
        <CssBaseline />
        <GoogleAnalytics />
      </ThemeProvider>
    </SnackbarProvider>
  );
};
