import React from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

export const CLink = ({ to, children, onClick, isLink = true, ...other }) => {
  const classes = useStyles();
  if (!isLink)
    return (
      <span onClick={() => onClick && onClick()} className={classes.link}>
        {children}
      </span>
    );
  return (
    <Link className={classes.link} to={to} {...other}>
      {children || ""}
    </Link>
  );
};

const useStyles = makeStyles(() => ({
  link: {
    color: "#074ce7",
    transition: "all .3s",
    textDecoration: "underline",
    cursor: "pointer",
    "&:hover": {
      textDecoration: "none",
    },
  },
}));
