import React, { useMemo } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import styles from "./timetable.module.scss";
import moment from "moment";
import "moment-timezone";
import "moment/locale/ru";
import { timetableFormats, timetableMessages } from "./services";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";

require("globalize/lib/cultures/globalize.culture.ru-RU");

const localizer = momentLocalizer(moment);

export const TimetableComponent = ({
  onEventClick,
  events,
  resources,
  onNavigate,
  showToolbar = true,
  selectedDate = null,
  datesInfo = null,
}) => {
  const onNavigateChange = (newDate) => {
    onNavigate && onNavigate(newDate);
  };

  const components = useMemo(
    () => ({
      dateCellWrapper: (props) => <DateCellWrapper {...props} />,
      month: {
        dateHeader: (props) => (
          <CustomMonthDateHeader
            {...props}
            selectedDate={selectedDate}
            datesInfo={datesInfo}
            onClick={onEventClick}
          />
        ),
        header: CustomMonthHeader,
      },
    }),
    [onEventClick, selectedDate, datesInfo]
  );

  return (
    <Calendar
      localizer={localizer}
      formats={timetableFormats}
      startAccessor="start_date"
      endAccessor="end_date"
      events={events}
      culture={"ru-RU"}
      onNavigate={onNavigateChange}
      components={components}
      messages={timetableMessages}
      style={{ height: 300 }}
      toolbar={showToolbar}
      views={{ month: true, week: false }}
      timeslots={1}
      resources={resources || undefined}
    />
  );
};

const DateCellWrapper = ({ children }) => {
  let disabled = false;

  return (
    <div
      className={`${styles.timetable__cell} ${
        disabled ? styles.timetable__cell_disabled : ""
      }`}
    >
      <div>{children}</div>
    </div>
  );
};

const CustomMonthDateHeader = ({ date, onClick, selectedDate, datesInfo }) => {
  const classes = useStyles();

  const residentsLength = useMemo(() => {
    if (!datesInfo?.length || !date) return 0;
    const dateInfo = datesInfo?.find(
      (item) => new Date(item.date).getTime() === new Date(date).getTime()
    );
    if (!dateInfo) return 0;
    const residents = dateInfo?.resident_names;
    return residents?.length;
  }, [datesInfo, date]);

  const isFilled = useMemo(() => !!residentsLength, [residentsLength]);
  return (
    <div
      className={clsx(classes.monthDateCell, {
        [classes.monthDateCellFilled]: isFilled,
        [classes.monthDateCellSelected]:
          new Date(date).getTime() === new Date(selectedDate).getTime(),
      })}
      onClick={() => onClick && isFilled && onClick(date)}
    >
      {moment(date).date()}
      {!!residentsLength && (
        <div className={classes.monthDateCellInfo}>({residentsLength})</div>
      )}
    </div>
  );
};

const CustomMonthHeader = ({ label }) => {
  const classes = useStyles();

  return <div className={classes.monthHeaderCell}>{label}</div>;
};

const useStyles = makeStyles(() => ({
  monthHeaderCell: {
    background: "rgb(41, 108, 175)",
    color: "white",
    fontSize: "14px",
    fontWeight: "bold",
    textAlign: "center",
    padding: "10px 5px",
    textTransform: "capitalize",
  },
  monthDateCell: {
    textAlign: "center",
    fontWeight: 600,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    fontSize: 16,
    color: "#1d4e89",
    transition: "all .3s",
  },
  monthDateCellSelected: {
    background: "rgb(146, 187, 255) !important",
  },
  monthDateCellFilled: {
    background: "rgb(215, 230, 255)",
    cursor: "pointer",
  },
  monthDateCellInfo: {
    fontSize: 11,
    color: "#396b8f",
    marginLeft: 3,
  },
}));
