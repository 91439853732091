import { makeStyles, TableRow, Tooltip } from "@material-ui/core";
import { FiltersWrapper } from "components/common/blocks";
import { CLink } from "components/common/blocks/CustomLink";
import { CollapseBlock } from "components/common/CollapseBlock/collapseBlock";
import {
  CustomTable,
  CustomTableCell,
  CustomTableHeadCell,
  CustomTableRow,
} from "components/common/CustomTable/CustomTable";
import { AutoSuggest } from "components/common/form/AutoSuggest";
import React, { useEffect, useMemo, useState } from "react";
import {
  dispatcherHeadItems,
  getChipColor,
  getDispatcherData,
  getDispatcherResidents,
} from "./services";
import { DispatcherRemontDetails } from "./DispatcherRemontDetails";
import { PAGES_ROUTE_KEY } from "../../constants";
import { useSearchParams } from "react-router-dom";
import { DispatcherRemontOkk } from "./DispatcherRemontOkk";
import ErrorIcon from "@material-ui/icons/Error";

export const Dispatcher = () => {
  const classes = useStyles();
  const [searchParams, setSearchParams] = useSearchParams();
  const [activeRemontId, setActiveRemontId] = useState(
    +searchParams.get("remontId") || null
  );
  const [activePage, setActivePage] = useState(
    searchParams.get("page") || null
  );
  const [isFetching, setIsFetching] = useState(false);
  const [data, setData] = useState([]);
  const [residents, setResidents] = useState([]);
  const [filters, setFilters] = useState({
    resident_id: "",
  });

  useEffect(() => {
    const getFilters = async () => {
      const res = await getDispatcherResidents();
      setResidents(res || []);
    };
    getFilters();
  }, []);

  useEffect(() => {
    const getData = async () => {
      setIsFetching(true);
      const res = await getDispatcherData(filters);
      setIsFetching(false);
      setData(res || []);
    };
    getData();
  }, [filters]);

  const onFiltersChange = (key, value) => {
    setFilters((prev) => ({ ...prev, [key]: value }));
  };

  const handleRemontClick = (remontId, page) => {
    setActivePage(remontId ? page : null);
    setActiveRemontId(remontId || null);
    setSearchParams(
      remontId ? { remontId: String(+remontId || ""), page } : {}
    );
  };

  const DetailsModeComponent = useMemo(() => {
    if (!activeRemontId) return null;
    if (activePage === "OKK") return DispatcherRemontOkk;
    if (activePage === "MASTERS") return DispatcherRemontDetails;
  }, [activeRemontId, activePage]);

  const renderTableHeader = ({ order, orderBy, handleRequestSort }) => {
    return (
      <>
        {dispatcherHeadItems.map((items, i) => {
          return (
            <TableRow key={i}>
              {items.map((item, i) => {
                let sortOptions = {};
                if (item.sort) {
                  sortOptions = {
                    order,
                    orderBy,
                    handleRequestSort,
                  };
                }

                return (
                  <CustomTableHeadCell key={i} {...item} {...sortOptions} />
                );
              })}
            </TableRow>
          );
        })}
      </>
    );
  };
  return (
    <div className={classes.wrapper}>
      {DetailsModeComponent ? (
        <DetailsModeComponent
          remontId={activeRemontId}
          goBack={() => handleRemontClick(null)}
        />
      ) : (
        <>
          <CollapseBlock className={classes.filters}>
            <FiltersWrapper>
              <div className={classes.filtersItem}>
                <AutoSuggest
                  withMargin
                  list={residents}
                  showKey={"resident_name"}
                  valueKey={"resident_id"}
                  placeholder="Выберите ЖК"
                  label="ЖК"
                  value={filters.resident_id}
                  onChange={(e) =>
                    onFiltersChange("resident_id", e?.target?.value)
                  }
                />
              </div>
            </FiltersWrapper>
          </CollapseBlock>
          <CustomTable
            isFetching={isFetching}
            headItems={[]}
            borderSeparate
            headerRender={renderTableHeader}
            rowsData={data}
            isEmpty={!data?.length}
            borderSpacing={1}
            backgroundColor={"rgba(189, 189, 211, .5)"}
          >
            {({ sortedData }) =>
              sortedData?.map((item) => {
                const isMastersOverdue =
                  !!item.not_started_overdue || !!item.overdue_by_now;
                const [
                  assignedMastersCount,
                  allMastersCount,
                ] = item.team_master_count?.split("/");
                const [doneOkkCount, allOkkCount] = item.okk_count?.split("/");
                const [
                  donePaymentsCount,
                  allPaymentsCount,
                ] = item.cost_count?.split("/");
                return (
                  <CustomTableRow key={item.remont_id}>
                    <CustomTableCell align={"center"}>
                      <CLink
                        to={`${PAGES_ROUTE_KEY.singleRemont}/${item?.remont_id}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {item?.remont_id}
                      </CLink>
                    </CustomTableCell>
                    <CustomTableCell align={"center"}>
                      {item.jur_remont_date_end}
                    </CustomTableCell>
                    <CustomTableCell align={"left"}>
                      {item.address}
                    </CustomTableCell>
                    {/* <CustomTableCell align={"center"}> */}
                    {/* <div className={classes.checkWrapper}>
                        Горячая вода <CheckIconWrapper />
                      </div>
                      <div className={classes.checkWrapper}>
                        Горячая вода <CloseIconWrapper />
                      </div> */}
                    {/* </CustomTableCell> */}
                    <CustomTableCell align={"center"} style={{ minWidth: 95 }}>
                      <div
                        style={{
                          position: "relative",
                        }}
                      >
                        {isMastersOverdue && (
                          <Tooltip title="Есть проcроченные работы">
                            <ErrorIcon
                              style={{
                                position: "absolute",
                                left: "12px",
                                top: "2px",
                                color: "#f13b3b",
                                fontSize: 20,
                              }}
                            />
                          </Tooltip>
                        )}
                        <CustomChip
                          onClick={() =>
                            handleRemontClick(item.remont_id, "MASTERS")
                          }
                          color={getChipColor(
                            Number(assignedMastersCount),
                            Number(allMastersCount)
                          )}
                          title="Перейти"
                        >
                          {item.team_master_count}
                        </CustomChip>
                      </div>
                    </CustomTableCell>
                    <CustomTableCell align={"center"}>
                      <CustomChip
                        onClick={() => handleRemontClick(item.remont_id, "OKK")}
                        color={getChipColor(
                          Number(doneOkkCount),
                          Number(allOkkCount)
                        )}
                      >
                        {item.okk_count}
                      </CustomChip>
                    </CustomTableCell>
                    <CustomTableCell align={"center"}>
                      <CustomChip
                        color={getChipColor(
                          Number(donePaymentsCount),
                          Number(allPaymentsCount)
                        )}
                      >
                        {item.cost_count}
                      </CustomChip>
                    </CustomTableCell>
                    <CustomTableCell align={"right"}>
                      {item.not_started}
                    </CustomTableCell>
                    <CustomTableCell align={"right"}>
                      {item.in_progress}
                    </CustomTableCell>
                    <CustomTableCell
                      align={"right"}
                      style={{
                        backgroundColor:
                          item.not_started_overdue > 0 && "#ffb9b9",
                      }}
                    >
                      {item.not_started_overdue}
                    </CustomTableCell>
                    <CustomTableCell
                      align={"right"}
                      style={{
                        backgroundColor: item.overdue_by_now > 0 && "#ffb9b9",
                      }}
                    >
                      {item.overdue_by_now}
                    </CustomTableCell>
                    <CustomTableCell align={"right"}>
                      {item.completed}
                    </CustomTableCell>
                  </CustomTableRow>
                );
              })
            }
          </CustomTable>
        </>
      )}
    </div>
  );
};

const useStyles = makeStyles({
  wrapper: {
    padding: 15,
  },
  chipWrapper: { display: "flex", justifyContent: "center", cursor: "pointer" },
  chip: {
    padding: "3px 10px",
    borderRadius: 20,
    backgroundColor: (props) => props.color || "#fbbc04",
    width: (props) => props.chipWidth || "min-content",
    fontWeight: 700,
    fontSize: 12,
    color: (props) => props.textColor || "#fff",
    textAlign: "center",
  },
  checkWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: 5,
  },
  filters: {
    marginBottom: " 15px",
  },
  filtersItem: {
    width: 210,
  },
});

export const CustomChip = ({
  children,
  color,
  onClick,
  textColor = "#fff",
  width = "min-content",
  title = null,
}) => {
  const classes = useStyles({ color, textColor, chipWidth: width });
  if (title)
    return (
      <Tooltip title={title}>
        <div
          className={classes.chipWrapper}
          onClick={() => onClick && onClick()}
        >
          <div className={classes.chip}>{children}</div>
        </div>
      </Tooltip>
    );
  return (
    <div className={classes.chipWrapper} onClick={() => onClick && onClick()}>
      <div className={classes.chip}>{children}</div>
    </div>
  );
};
