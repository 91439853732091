import React, { useEffect, useMemo, useState } from "react";
import { getOkkData, okkCheckBtnColors } from "./services";
import { CustomLoader } from "components/common/CustomLoader";
import { BackBtn, CustomButton } from "components/common/blocks";
import { NotFound } from "components/common/text";
import { makeStyles } from "@material-ui/core";
import { MODALS_NAMES } from "../../constants";
import { useDispatch } from "react-redux";
import { showPopUp } from "services/redux/actions/app-actions";
import { CustomSearch } from "components/common/form/CustomSearch";

export const DispatcherRemontOkk = ({ remontId, goBack }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [okkData, setOkkData] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    const getData = async () => {
      setIsFetching(true);
      const res = await getOkkData(remontId);
      setIsFetching(false);
      setOkkData(res);
    };
    getData();
  }, [remontId]);

  const onOkkSubmit = async (res) => {
    setOkkData(res || null);
  };

  const handleClickWorkSet = (work_set = {}, check_status_code) => {
    dispatch(
      showPopUp(true, MODALS_NAMES.okkCheck, {
        ...work_set,
        close: true,
        actions: check_status_code !== "CHECKED" && !!work_set?.media?.length,
        remont_id: remontId,
        onSubmit: onOkkSubmit,
      })
    );
  };

  const onSearchChange = (e) => {
    setSearchTerm(e.target?.value);
  };

  const okkDataEdited = useMemo(() => {
    if (!okkData?.work_sets.length) return null;

    let edited = Object.keys(okkCheckBtnColors).map((key) => {
      const item =
        okkData.work_sets?.find((item) => item.check_status_code === key) || {};
      return {
        ...item,
        textColor: okkCheckBtnColors[key]?.textColor || "",
        color: okkCheckBtnColors[key]?.secondColor || "",
        label: okkCheckBtnColors[key]?.label || "",
      };
    });

    if (!searchTerm) return edited;
    return edited.map((item) => {
      return {
        ...item,
        specialities: item.specialities
          ?.map((item) => {
            const filtered = item?.work_sets?.filter((item) =>
              item.work_set_name
                ?.toLowerCase()
                ?.includes(searchTerm?.toLowerCase())
            );
            if (!filtered?.length) return null;

            return { ...item, work_sets: filtered };
          })
          .filter((item) => !!item),
      };
    });
  }, [okkData, searchTerm]);

  return (
    <div>
      <BackBtn onClick={goBack} big padding mobile={false} />
      <h2 className={classes.title}>
        Справочник ОКК{" "}
        {okkData?.remont_info?.address
          ? `(${okkData?.remont_info?.address})`
          : ""}
      </h2>
      {isFetching ? (
        <CustomLoader />
      ) : okkData ? (
        <div className={classes.content}>
          <div className={classes.info}>
            <div className={classes.dateRow}>
              <span>Начало ремонта:</span>{" "}
              {okkData?.remont_info?.jur_remont_date_begin}
            </div>
            <div className={classes.dateRow}>
              <span>Завершение ремонта:</span>{" "}
              {okkData?.remont_info?.jur_remont_date_end}
            </div>
          </div>
          <CustomSearch handleChange={onSearchChange} value={searchTerm} />
          <div className={classes.workSetsWrapper}>
            {okkDataEdited?.map((col) => {
              return (
                <div key={col.check_status_code} className={classes.column}>
                  <div
                    className={classes.columnHeader}
                    style={{ backgroundColor: col.color, color: col.textColor }}
                  >
                    {col.label || ""}
                  </div>
                  {col.specialities?.length ? (
                    col.specialities?.map((speciality, i) => {
                      return (
                        <React.Fragment key={speciality.speciality_id}>
                          <div className={classes.speciality}>
                            <span className={classes.specialityTitle}>
                              {speciality?.speciality_name}
                            </span>
                            <div className={classes.workSetList}>
                              {speciality?.work_sets?.map((workSet) => (
                                <CustomButton
                                  key={workSet?.work_set_id}
                                  className={classes.workSetItem}
                                  onClick={() =>
                                    handleClickWorkSet(
                                      workSet,
                                      col.check_status_code
                                    )
                                  }
                                  style={{
                                    backgroundColor:
                                      okkCheckBtnColors[col.check_status_code]
                                        ?.color || "#0C4B86",
                                  }}
                                >
                                  {`${workSet?.work_set_name}${
                                    workSet?.team_master_fio ||
                                    workSet?.phone_number
                                      ? ` (${workSet?.team_master_fio || ""} ${
                                          workSet?.phone_number
                                            ? workSet?.phone_number
                                            : ""
                                        })`
                                      : " (Мастер не назначен)"
                                  }`}
                                </CustomButton>
                              ))}
                            </div>
                          </div>
                          {col.specialities?.length - 1 !== i && (
                            <div className={classes.line} />
                          )}
                        </React.Fragment>
                      );
                    })
                  ) : (
                    <NotFound>Не найдено</NotFound>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        <NotFound />
      )}
    </div>
  );
};

const useStyles = makeStyles({
  content: {
    marginTop: 15,
  },
  title: {
    fontSize: 18,
    marginBottom: 15,
    fontWeight: 600,
  },
  workSetsWrapper: {
    marginTop: 7,
    display: "flex",
    gap: 15,
    alignItems: "flex-start",
    width: "100%",
    overflowX: "auto",
    maxHeight: "calc(100vh - 270px)",
  },
  column: {
    width: 300,
    minWidth: 300,
    display: "flex",
    flexDirection: "column",
    gap: 10,
  },
  columnHeader: {
    padding: "20px 15px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontWeight: 700,
    borderRadius: 4,
    fontSize: 16,
  },
  workSetItem: {
    width: "100%",
    whiteSpace: "initial",
    height: "auto",
    lineHeight: "18px",
    minHeight: 50,
  },
  info: {
    marginBottom: 15,
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    gap: 15,
  },
  dateRow: {
    fontSize: 16,
    fontWeight: 600,
    "& > span": {
      fontWeight: 400,
    },
  },
  speciality: {
    padding: 0,
  },
  specialityTitle: {
    marginBottom: 7,
    display: "block",
    color: "rgb(77, 77, 77)",
    fontSize: "15px",
    paddingLeft: "5px",
  },
  workSetList: {
    display: "flex",
    flexDirection: "column",
    gap: 10,
  },
  line: {
    height: 1,
    borderTop: "1px solid #ccc",
    width: "88%",
    margin: "0 auto",
    marginTop: "8px",
  },
});
