import instance from "services/api";

export const dispatcherAPI = {
  async getList(params = {}) {
    return instance()
      .get("partner/dispatcher/read/", { params })
      .then((res) => res?.data);
  },
  async getRemontDetails(remontId) {
    return instance()
      .get(`partner/dispatcher/remont/${remontId}/`)
      .then((res) => res?.data);
  },
  async getMasterBusyness(body) {
    return instance()
      .post(`partner/dispatcher/team_masters/busyness/`, body)
      .then((res) => res?.data);
  },
  async assignMasterToWorkSet(body) {
    return instance()
      .post(`partner/dispatcher/team_masters/request/`, body)
      .then((res) => res?.data);
  },
  async getResidents() {
    return instance()
      .get("partner/dispatcher/residents/")
      .then((res) => res?.data);
  },
  async getMasters(body) {
    return instance()
      .post("partner/dispatcher/team_masters/", body)
      .then((res) => res?.data);
  },
  async getOkkData(remontId) {
    return instance()
      .get(`partner/dispatcher/remont/${remontId}/okk/`)
      .then((res) => res?.data);
  },
  async okkCheck(remontId, body) {
    return instance()
      .post(`partner/dispatcher/remont/${remontId}/okk/`, body, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => res?.data);
  },
};
