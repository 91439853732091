import { Button, Popover } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import React from "react";
import { LoaderData } from "./LoaderData";

export const CButton = ({
  loading,
  className,
  disabled,
  confirmMode = false,
  confirmTitle = "",
  mode = "",
  onClick = () => {},
  children,
  variant = "",
  type,
  ...other
}) => {
  const classes = useStyles({ variant });
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    if (confirmMode) return setAnchorEl(event.currentTarget);
    onClick && onClick(event);
  };

  const handleClose = () => {
    if (loading) return;
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <>
      <Button
        type={type || "button"}
        disabled={disabled || loading}
        onClick={handleClick}
        mode={mode}
        classes={{ disabled: classes.disabled }}
        {...other}
        className={clsx(
          classes.btn,
          { [classes.btnAuto]: mode === "auto" },
          className || "",
          { [classes.disabled]: disabled || loading }
        )}
        color={"primary"}
      >
        {loading && !confirmMode ? (
          <LoaderData small white />
        ) : (
          children || "Добавить"
        )}
      </Button>
      {confirmMode && (
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
        >
          <div className={classes.confirmWrapper}>
            <h3 className={classes.confirmTitle}>
              {confirmTitle || "Подтвердите действие"}
            </h3>
            <div className={classes.footer}>
              <Button
                className={classes.confirmBtn}
                style={{
                  backgroundColor: "#dd2e2e",
                }}
                disabled={loading}
                onClick={handleClose}
              >
                Отмена
              </Button>
              <Button
                className={classes.confirmBtn}
                style={{
                  backgroundColor: "#13b221",
                }}
                disabled={loading}
                onClick={onClick}
              >
                {loading ? <LoaderData small white /> : "Подтвердить"}
              </Button>
            </div>
          </div>
        </Popover>
      )}
    </>
  );
};

const useStyles = makeStyles({
  btn: {
    width: 200,
    height: 38,
    padding: 10,
    borderRadius: 4,
    color: (props) => (props.variant === "secondary" ? "#0C4B86" : "#fff"),
    backgroundColor: (props) =>
      props.variant === "secondary" ? "#fff" : "#0C4B86",
    cursor: "pointer",
    border: (props) =>
      props.variant === "secondary" ? "1px solid #0C4B86" : "none",
    "&:hover": {
      backgroundColor: (props) =>
        props.variant === "secondary" ? "#fff" : "#0C4B86",
      color: (props) => (props.variant === "secondary" ? "#0764bd" : "#fff"),
    },
  },
  btnAuto: {
    width: "auto",
    minWidth: "unset",
  },
  disabled: {
    backgroundColor: "rgba(70,70,70,0.12) !important",
    color: "#4f4f4f",
  },
  footer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    gap: 10,
    marginTop: 15,
  },
  confirmWrapper: {
    padding: 12,
  },
  confirmTitle: {
    margin: 0,
  },
  confirmBtn: {
    color: "#fff",
    fontWeight: 600,
  },
});
