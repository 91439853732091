import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { addStageMediaReport, getStageMediaReportData } from "./services";
import { useDispatch } from "react-redux";
import { showPopUp } from "../../../services/redux/actions/app-actions";
import { MAIN_URL, MODALS_NAMES } from "../../../constants";
import { LinearProgress } from "@material-ui/core";
import { UploadFile } from "../../../components/common/form/UploadFile";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import { LoaderData } from "components/common/LoaderData";
import { useCustomSnackbar } from "utils/hooks/useCustomSnackbar";

export const RemontStageMediaReport = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { addDefaultSnack } = useCustomSnackbar();
  const { remont_id: remont_id_str } = useParams();
  const remont_id = Number(remont_id_str);
  const [isFetching, setIsFetching] = useState(false);
  const [loading, setLoading] = useState({});
  const [stages, setStages] = useState([]);
  const [currentStageId, setCurrentStageId] = useState(null);

  useEffect(() => {
    const getData = async () => {
      setIsFetching(true);
      const res = await getStageMediaReportData(remont_id);
      setIsFetching(false);
      setStages(res || []);
    };
    getData();
  }, [remont_id]);

  const onImageClick = (idx, images) => {
    dispatch(
      showPopUp(true, MODALS_NAMES.gallery, {
        activeIndex: idx,
        photoUrlKey: "file_url",
        images,
      })
    );
  };
  const addFile = async (value) => {
    if (!currentStageId) return;
    if (!value || loading[currentStageId]) return;
    setLoading((prev) => ({ ...prev, [currentStageId]: true }));
    const res = await addStageMediaReport(remont_id, currentStageId, value);
    setLoading((prev) => ({ ...prev, [currentStageId]: false }));
    if (!res) return;
    addDefaultSnack("Успешно");
    setStages(res);
  };
  return (
    <div className={classes.wrapper}>
      {isFetching && <LinearProgress color="primary" />}

      <div className={classes.stageWrapper}>
        <div className={classes.row}>
          <div className={classes.headCell}>Этап</div>
          <div className={classes.headCell}>Загрузка</div>
        </div>
      </div>
      {stages?.map((item) => {
        return (
          <div className={classes.stageWrapper} key={item.stage_id}>
            <div className={classes.row}>
              <div className={classes.stageName}>{item.stage_name}</div>
              <UploadFile
                isAnketa
                disabled={loading[item.stage_id]}
                onChange={(e) => addFile(Object.values(e.target.files))}
                files={[]}
                renderUpload={() => (
                  <span
                    className={classes.attach}
                    onClick={() => setCurrentStageId(item.stage_id)}
                  >
                    {loading[item.stage_id] ? (
                      <LoaderData small />
                    ) : (
                      <>
                        <AttachFileIcon className={classes.attachIcon} />
                        Загрузить
                      </>
                    )}
                  </span>
                )}
              />
            </div>
            {!!item.photos?.length && (
              <div className={classes.images}>
                {item.photos.map((photoItem, i, arr) => {
                  return (
                    <div className={classes.imageWrapper} key={i}>
                      <img
                        className={classes.image}
                        src={MAIN_URL + photoItem.file_mini_url}
                        onClick={() => onImageClick(i, arr)}
                      />
                      <span className={classes.imageDate}>
                        {photoItem.date_send}
                      </span>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    maxWidth: 800,
  },
  row: {
    display: "grid",
    gridTemplateColumns: "10fr 2fr",
    gap: 15,
    alignItems: "center",
    [theme.breakpoints.down(576)]: {
      gridTemplateColumns: "12fr 100px",
    },
  },
  headCell: {
    fontWeight: 700,
    fontSize: 16,
  },
  stageWrapper: {
    padding: "15px 10px",
    borderBottom: "1px solid #ccc",
  },
  stageName: {
    fontWeight: 400,
    fontSize: 16,
    lineHeight: "20px",
  },
  images: {
    display: "flex",
    gap: 15,
    flexWrap: "wrap",
    marginTop: 20,
  },
  imageWrapper: {
    position: "relative",
  },
  image: {
    width: 100,
    borderRadius: 4,
    cursor: "pointer",
  },
  imageDate: {
    position: "absolute",
    bottom: "-8px",
    right: "3px",
    fontSize: 10,
    color: "#616161",
  },
  attach: {
    backgroundColor: "#0C4B86",
    color: "#fff",
    padding: 8,
    display: "flex",
    alignItems: "center",
    gap: 5,
    borderRadius: 5,
    width: "fit-content",
    cursor: "pointer",
  },
  attachIcon: {
    fontSize: 15,
  },
}));
