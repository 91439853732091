import instance from "../../services/api";

export const remontRequestAPI = {
  async getList() {
    return await instance()
      .get("/partner/contractor_request/read/")
      .then((res) => res?.data);
  },
  async answerToRequest(body) {
    return await instance()
      .post("/partner/contractor_request/accept/", body)
      .then((res) => res?.data);
  },
};
