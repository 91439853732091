import React, { useEffect, useState } from "react";
import {
  assignMasterToWorkSet,
  getChipColor,
  getDispatcherRemontDetails,
} from "./services";
import { CustomLoader } from "components/common/CustomLoader";
import { BackBtn, CustomButton } from "components/common/blocks";
import { NotFound } from "components/common/text";
import { CustomAccordion } from "components/common/blocks/CustomAccordion";
import { makeStyles, Tooltip } from "@material-ui/core";
import clsx from "clsx";
import { CustomChip } from "./Dispatcher";
import { MODALS_NAMES } from "../../constants";
import { useDispatch } from "react-redux";
import { showPopUp } from "services/redux/actions/app-actions";
import { useCustomSnackbar } from "utils/hooks/useCustomSnackbar";
import { Clickable } from "components/common/Clickable";
import { Error } from "@material-ui/icons";
import { useMemo } from "react";
import { CustomSearch } from "components/common/form/CustomSearch";

export const DispatcherRemontDetails = ({ remontId, goBack }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { addDefaultSnack } = useCustomSnackbar();
  const [remontInfo, setRemontInfo] = useState(null);
  const [isFetching, setIsFetching] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [expandedItems, setExpandedItems] = useState({});

  useEffect(() => {
    const getData = async () => {
      setIsFetching(true);
      const res = await getDispatcherRemontDetails(remontId);
      setIsFetching(false);
      setRemontInfo(res || null);
    };
    remontId && getData();
  }, [remontId]);

  const onAddMaster = async (body) => {
    const res = await assignMasterToWorkSet(body);
    if (!res) return;
    addDefaultSnack("Успешно");
    setRemontInfo(res);
    dispatch(showPopUp(false));
  };

  const handleAddMaster = (
    speciality_id = null,
    speciality_name = null,
    work_set_id = null,
    work_set_name = null,
    selected_master_id = ""
  ) => {
    dispatch(
      showPopUp(true, MODALS_NAMES.addMasterToWorkSetDispatcher, {
        work_set_name,
        selected_master_id,
        speciality_name,
        close: true,
        work_set_id,
        remont_id: remontId,
        speciality_id,
        onSubmit: onAddMaster,
      })
    );
  };

  const onSearchChange = (e) => {
    const value = e.target?.value;
    setSearchTerm(value);
    if (!value) return setExpandedItems({});
    if (!remontInfo.specialities?.length) return;
    const expandedItems = {};
    remontInfo.specialities.forEach((item) => {
      expandedItems[item.speciality_id] = true;
    });
    setExpandedItems(expandedItems);
  };

  const workSetsEdited = useMemo(() => {
    if (!remontInfo?.specialities?.length) return null;
    if (!searchTerm) return remontInfo?.specialities;

    return remontInfo.specialities
      ?.map((item) => {
        const filtered = item?.work_sets?.filter((item) =>
          item.work_set_name?.toLowerCase()?.includes(searchTerm?.toLowerCase())
        );
        if (!filtered?.length) return null;

        return { ...item, work_sets: filtered };
      })
      .filter((item) => !!item);
  }, [remontInfo, searchTerm]);

  const onExpandedChange = (speciality_id, value) => {
    setExpandedItems((prev) => ({ ...prev, [speciality_id]: value }));
  };

  return (
    <div>
      <BackBtn onClick={goBack} big padding mobile={false} />
      {isFetching ? (
        <CustomLoader />
      ) : remontInfo ? (
        <div className={classes.content}>
          <h2 className={classes.title}>{remontInfo?.remont_info?.address}</h2>
          <div className={classes.info}>
            <div className={classes.dateRow}>
              <span>Начало ремонта:</span>{" "}
              {remontInfo?.remont_info?.jur_remont_date_begin}
            </div>
            <div className={classes.dateRow}>
              <span>Завершение ремонта:</span>{" "}
              {remontInfo?.remont_info?.jur_remont_date_end}
            </div>
          </div>

          <CustomSearch handleChange={onSearchChange} value={searchTerm} />
          {workSetsEdited?.length ? (
            <div style={{ marginTop: "15px" }}>
              {workSetsEdited?.map((item, i) => {
                const isSpecMastersOverdue =
                  !!item.not_started_overdue || !!item.overdue_by_now;
                return (
                  <CustomAccordion
                    loading={false}
                    defaultExpanded={!!searchTerm}
                    contentAllWidth
                    mobileDirectionColumn
                    checkLabelClick={false}
                    key={item?.speciality_id}
                    expandedProps={!!expandedItems[item.speciality_id]}
                    onChange={(value) =>
                      onExpandedChange(item.speciality_id, value)
                    }
                    headerStyle={{
                      backgroundColor: isSpecMastersOverdue && "#ffb9b9",
                    }}
                    renderData={() => (
                      <div
                        className={clsx(
                          classes.flex,
                          classes.flexSpaceBetween,
                          classes.flexMobile
                        )}
                      >
                        <div className={classes.grid}>
                          <div className={classes.speciality}>
                            {`${i + 1}. ${item.speciality_name}`}
                          </div>
                          <CustomChip
                            title="Назначено мастеров"
                            color={getChipColor(
                              item.work_set_master_attached_count,
                              item.work_set_count
                            )}
                          >{`${item.work_set_master_attached_count}/${item.work_set_count}`}</CustomChip>
                          {(item.date_begin || item.date_end) && (
                            <CustomChip
                              title={"Дата начала - Дата окончания"}
                              width="auto"
                              color={
                                isSpecMastersOverdue
                                  ? "#e54343"
                                  : "rgb(48, 138, 227)"
                              }
                            >{`${item.date_begin} - ${item.date_end}`}</CustomChip>
                          )}
                        </div>
                      </div>
                    )}
                    renderRightData={() =>
                      !!item.team_master_fio_list ? (
                        <Clickable
                          onClick={() =>
                            handleAddMaster(
                              item.speciality_id,
                              item.speciality_name
                            )
                          }
                        >
                          {item.team_master_fio_list}
                        </Clickable>
                      ) : (
                        <CustomButton
                          className={classes.addBtn}
                          onClick={() =>
                            handleAddMaster(
                              item.speciality_id,
                              item.speciality_name
                            )
                          }
                        >
                          Добавить мастера
                        </CustomButton>
                      )
                    }
                  >
                    <div className={classes.workSetsWrapper}>
                      {item.work_sets.map((workSet) => {
                        const isMastersOverdue =
                          !!workSet.not_started_overdue ||
                          !!workSet.overdue_by_now;
                        return (
                          <div
                            key={workSet.work_set_id}
                            className={clsx(classes.workSetItem, {
                              [classes.workSetItemOverdue]: isMastersOverdue,
                            })}
                          >
                            <div
                              className={clsx(
                                classes.workSetGrid,
                                classes.flexSpaceBetween
                              )}
                            >
                              <div className={classes.workSetName}>
                                {workSet.work_set_name}
                              </div>
                              {workSet.date_begin_work_set ||
                              workSet.date_end_work_set ? (
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 5,
                                  }}
                                >
                                  <Tooltip title="Дата начала - Дата окончания">
                                    <div
                                      className={classes.workSetDate}
                                    >{`${workSet.date_begin_work_set ||
                                      ""} - ${workSet.date_end_work_set ||
                                      ""}`}</div>
                                  </Tooltip>
                                  {isMastersOverdue && (
                                    <Tooltip
                                      title={
                                        workSet.not_started_overdue
                                          ? "Не начат"
                                          : "Начат"
                                      }
                                    >
                                      <Error
                                        style={{
                                          color: "#f13b3b",
                                          fontSize: 20,
                                        }}
                                      />
                                    </Tooltip>
                                  )}
                                </div>
                              ) : (
                                <div></div>
                              )}
                              {workSet.team_master_fio ? (
                                <div style={{ textAlign: "right" }}>
                                  <Clickable
                                    onClick={() =>
                                      handleAddMaster(
                                        item.speciality_id,
                                        null,
                                        workSet.work_set_id,
                                        workSet.work_set_name,
                                        workSet?.team_master_id
                                      )
                                    }
                                  >
                                    {workSet.team_master_fio}
                                  </Clickable>
                                </div>
                              ) : (
                                <div className={classes.addBtnWrapper}>
                                  <CustomButton
                                    className={classes.addBtn}
                                    onClick={() =>
                                      handleAddMaster(
                                        item.speciality_id,
                                        null,
                                        workSet.work_set_id,
                                        workSet.work_set_name
                                      )
                                    }
                                  >
                                    Добавить мастера
                                  </CustomButton>
                                </div>
                              )}
                            </div>
                            <div
                              className={classes.flex}
                              style={{ marginTop: 10 }}
                            >
                              <CustomChip color={"#0c4b86"} width="auto">
                                {workSet.amount || "-"}
                              </CustomChip>
                              <CustomChip color={"#0c4b86"} width="auto">
                                {workSet.price || "-"}
                              </CustomChip>
                              <CustomChip color={"#0c4b86"} width="auto">
                                {workSet.sum || "-"}
                              </CustomChip>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </CustomAccordion>
                );
              })}
            </div>
          ) : (
            <NotFound />
          )}
        </div>
      ) : (
        <NotFound />
      )}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  content: {
    marginTop: 15,
  },
  title: {
    fontSize: 18,
    marginBottom: 15,
    fontWeight: 600,
  },
  info: {
    marginBottom: 15,
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    gap: 15,
  },
  dateRow: {
    fontSize: 16,
    fontWeight: 600,
    "& > span": {
      fontWeight: 400,
    },
  },
  speciality: {
    fontWeight: 600,
    fontSize: 15,
  },
  workSetsWrapper: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: 10,
    marginTop: 7,
  },
  flex: {
    display: "flex",
    alignItems: "center",
    gap: 10,
    flex: 1,
  },
  flexMobile: {
    [theme.breakpoints.down(400)]: {
      flexDirection: "column",
      alignItems: "flex-start",
    },
  },
  grid: {
    display: "grid",
    alignItems: "center",
    gridTemplateColumns: "300px 55px 145px",
    gap: 10,
    flex: 1,
    [theme.breakpoints.down(991)]: {
      display: "flex",
      flexWrap: "wrap",
    },
  },
  workSetGrid: {
    display: "grid",
    alignItems: "center",
    gridTemplateColumns: "350px 170px 250px",
    gap: 10,
    [theme.breakpoints.down(991)]: {
      display: "flex",
      flexWrap: "wrap",
    },
  },
  flexSpaceBetween: { justifyContent: "space-between" },
  workSetItem: {
    width: "100%",
    padding: 15,
    borderRadius: 8,
    boxShadow: "0px 1px 17px rgba(108, 108, 108, .15)",
  },
  workSetItemOverdue: {
    backgroundColor: "#ffb9b9",
  },
  workSetName: {
    fontSize: 15,
    fontWeight: 600,
  },
  workSetDate: {
    fontSize: 14,
    fontWeight: 600,
  },
  addBtn: {
    fontSize: 12,
    fontWeight: 600,
    padding: "0 5px",
    height: 30,
    width: 110,
  },
  addBtnWrapper: {
    display: "flex",
    justifyContent: "flex-end",
  },
}));
