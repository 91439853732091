import { PageTitle } from "components/common/pageTitle";
import React from "react";
import { Dispatcher } from "./Dispatcher";

export const DispatcherPage = () => {
  return (
    <>
      <PageTitle title={"Диспетчер мастеров"} />
      <Dispatcher />
    </>
  );
};
