import { remontRequestAPI } from "./api";

export const getRemontRequestList = async () => {
  try {
    const res = await remontRequestAPI.getList();
    return res?.data;
  } catch (e) {}
};

export const answerToRemontRequest = async (body) => {
  try {
    const res = await remontRequestAPI.answerToRequest(body);
    return res?.data;
  } catch (e) {}
};

export const contractorRequestsHeadItems = [
  { label: "Название" },
  { label: "ID", align: "center" },
  { label: "ТК", align: "center" },
  { label: "Проект", align: "center" },
  { label: "Статус", align: "center" },
];
