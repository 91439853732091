import React, { useEffect, useMemo, useState } from "react";
import { AutoSuggest } from "../../../common/form/AutoSuggest";
import { CButton } from "../../../common/buttons";
import { makeStyles } from "@material-ui/core/styles";
import {
  getDispatcherMasterBusyness,
  getDispatcherMasters,
  getFirstDayOfMonth,
} from "pages/dispatcher/services";
import { CustomDatePicker } from "components/common/form/CustomDatePicker";
import { TimetableComponent } from "components/common/Timetable/Timetable";
import moment from "moment";

export const AddMasterToWorkSetDispatcher = ({ data }) => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [masters, setMasters] = useState([]);
  const [mastersBusyness, setMastersBusyness] = useState([]);
  const [currentWeek, setCurrentWeek] = useState(
    getFirstDayOfMonth(new Date())
  );
  const {
    work_set_name,
    speciality_name,
    selected_master_id,
    work_set_id,
    speciality_id,
    remont_id,
    onSubmit,
  } = data;
  const [formData, setFormData] = useState({
    team_master_id: selected_master_id || "",
    date_begin: "",
    date_end: "",
  });

  useEffect(() => {
    const getMasters = async () => {
      const res = await getDispatcherMasters({ remont_id, speciality_id });
      setMasters(res || []);
    };
    getMasters();
  }, [speciality_id, remont_id]);

  useEffect(() => {
    const getMastersBusyness = async () => {
      if (!currentWeek || !formData.team_master_id)
        return setMastersBusyness(null);
      const res = await getDispatcherMasterBusyness({
        date: currentWeek,
        team_master_id: formData.team_master_id,
      });
      setMastersBusyness(res || null);
    };
    getMastersBusyness();
  }, [formData.team_master_id, currentWeek]);

  const handleSubmit = async () => {
    if (!onSubmit) return;
    const body = {
      ...formData,
      remont_id,
      speciality_id: work_set_id ? null : speciality_id,
      work_set_id,
    };
    setIsLoading(true);
    await onSubmit(body);
    setIsLoading(false);
  };

  const onChange = (key, value) => {
    setFormData((prev) => ({ ...prev, [key]: value || "" }));
  };

  const onWeekChange = (date) => {
    setCurrentWeek(getFirstDayOfMonth(date));
  };

  const selectedDateData = useMemo(() => {
    if (!selectedDate || !mastersBusyness?.length) return null;
    const dateInfo = mastersBusyness?.find(
      (item) =>
        new Date(item.date).getTime() === new Date(selectedDate).getTime()
    );
    if (!dateInfo) return null;
    const residents = dateInfo?.resident_names;
    return residents;
  }, [selectedDate, mastersBusyness]);

  const isSubmitDisabled = useMemo(() => {
    return (
      !formData.team_master_id || !formData.date_begin || !formData.date_end
    );
  }, [formData]);
  return (
    <div className={classes.wrapper}>
      <h2 className={classes.title}>Назначить мастера</h2>
      <h3 className={classes.workset}>{`${
        work_set_id ? "Работа" : "Специализация"
      }: ${work_set_id ? work_set_name || "" : speciality_name || ""}`}</h3>
      <div className={classes.form}>
        <AutoSuggest
          value={formData.team_master_id}
          onChange={(e) => onChange("team_master_id", e.target?.value)}
          list={masters}
          label={"Мастер"}
          specialOptionTitle={"Бригадир"}
          getSpecialOption={(option) => option.is_main_master}
          showKey={"team_master_fio"}
          valueKey={"team_master_id"}
        />
        <CustomDatePicker
          fullWidth
          absoluteMessage
          disablePast
          placeholder="Дата начала"
          value={formData?.date_begin}
          onChange={(e) => onChange("date_begin", e.target.value)}
        />
        <CustomDatePicker
          fullWidth
          absoluteMessage
          disablePast
          placeholder="Дата окончания"
          value={formData?.date_end}
          onChange={(e) => onChange("date_end", e.target.value)}
        />
        {!!formData?.team_master_id && (
          <div>
            <div className={classes.rowTitle}>Занятость мастера</div>
            <TimetableComponent
              onNavigate={onWeekChange}
              selectedDate={selectedDate}
              onEventClick={(date) => setSelectedDate(date)}
              datesInfo={mastersBusyness}
            />
            {selectedDateData && (
              <div className={classes.selectedDateData}>
                <p className={classes.selectedDateDataTitle}>
                  Список ЖК на {moment(selectedDate).format("DD.MM.YYYY")}
                </p>
                <div className={classes.selectedDateDataItems}>
                  {selectedDateData.map((residentName, i) => (
                    <div key={i} className={classes.selectedDateDataItem}>
                      {residentName || ""}
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        )}
      </div>

      <div className={classes.flex}>
        <CButton
          className={classes.submitBtn}
          disabled={isSubmitDisabled}
          loading={isLoading}
          onClick={handleSubmit}
        >
          Назначить
        </CButton>
      </div>
    </div>
  );
};
const useStyles = makeStyles((theme) => ({
  wrapper: {
    minWidth: 800,
    [theme.breakpoints.down(991)]: {
      minWidth: "100%",
    },
    [theme.breakpoints.down(768)]: {
      minWidth: "calc(100vw - 70px)",
    },
  },
  workset: {
    margin: 0,
    marginBottom: 10,
    color: "#002551",
  },
  submitBtn: {
    marginTop: 20,
  },
  title: {
    margin: 0,
    marginBottom: 10,
  },
  flex: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    gap: 20,
  },
  rowTitle: {
    fontWeight: 600,
    fontSize: 16,
    color: "#1d4e89",
    marginBottom: 10,
  },
  selectedDateData: {
    marginTop: 20,
  },
  selectedDateDataTitle: {
    marginBottom: 5,
    fontWeight: 700,
    color: "#1d4e89",
    fontSize: 16,
  },
  selectedDateDataItems: {
    display: "flex",
    flexDirection: "column",
    gap: 5,
  },
  selectedDateDataItem: {
    fontSize: 16,
  },
}));
