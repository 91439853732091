import React, { useMemo, useRef, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { LoaderData } from "../../LoaderData";

export const CustomAccordion = ({
  label = "",
  children,
  checkbox = false,
  disabled = false,
  contentAllWidth = false,
  mobileDirectionColumn = false,
  defaultExpanded = false,
  checkLabelClick = true,
  isHalfChecked,
  isChecked,
  valueKey = "",
  loading = {},
  renderData = null,
  renderRightData = null,
  handleCheckBoxClick,
  headerStyle = {},
  onChange,
  expandedProps,
}) => {
  const classes = useStyles();
  const wrapRef = useRef();
  const childrenDataRef = useRef();
  const childrenRightDataRef = useRef();
  const [expanded, setExpanded] = useState(!!defaultExpanded);

  const onClick = (e) => {
    if (checkLabelClick) {
      try {
        const isChildren = wrapRef?.current?.contains(e.target);
        if (!isChildren) return;
        const isChildrenData = childrenDataRef.current?.contains(e.target);
        if (e.target?.type === "checkbox" || isChildrenData) return;
      } catch (e) {}
    }
    const isRightChildrenData = childrenRightDataRef.current?.contains(
      e.target
    );
    if (isRightChildrenData) return;
    setExpanded((prev) => !prev);
    onChange && onChange(!expanded);
  };

  const currentExpanded = useMemo(() => {
    if (typeof expandedProps === "boolean") return expandedProps;
    return expanded;
  }, [expanded, expandedProps]);
  return (
    <Accordion
      className={classes.root}
      expanded={currentExpanded}
      ref={wrapRef}
      classes={{ expanded: classes.expanded }}
    >
      <AccordionSummary
        onClick={onClick}
        expandIcon={<ExpandMoreIcon />}
        classes={{ content: classes.summary }}
        aria-controls="panel1a-content"
        id="panel1a-header"
        style={headerStyle || {}}
      >
        {checkbox && (
          <Checkbox
            indeterminate={isHalfChecked}
            checked={isChecked}
            disabled={disabled}
            onClick={handleCheckBoxClick}
            classes={{ disabled: classes.disabled }}
            className={clsx(classes.checkbox, {
              [classes.readyCheckbox]: isChecked,
            })}
            color={"primary"}
          />
        )}
        <div
          className={clsx(classes.content, {
            [classes.contentAllWidth]: contentAllWidth,
            [classes.mobileDirectionColumn]: mobileDirectionColumn,
          })}
        >
          {!!label && (
            <Typography className={classes.heading}>{label}</Typography>
          )}
          {renderData && (
            <div ref={childrenDataRef} className={classes.headingRender}>
              {renderData()}
              {renderRightData && (
                <div ref={childrenRightDataRef}>{renderRightData()}</div>
              )}
            </div>
          )}
          {loading[valueKey] && (
            <div className={classes.loader}>
              <LoaderData small />
            </div>
          )}
        </div>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& .MuiAccordionSummary-root.Mui-expanded": {
      minHeight: 48,
    },
    "& .MuiAccordionSummary-content.Mui-expanded": {
      margin: 0,
    },
  },
  content: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    gap: "10px",
  },
  contentAllWidth: {
    width: "100%",
    "& > div": {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      gap: 10,
      [theme.breakpoints.down(400)]: {
        flexWrap: "wrap",
      },
    },
  },
  mobileDirectionColumn: {
    "& > div": {
      [theme.breakpoints.down(400)]: {
        flexWrap: "wrap",
        flexDirection: "column",
        alignItems: "flex-start",
      },
    },
  },
  loader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: 20,
  },
  readyCheckbox: {
    color: "#1aab09 !important",
    borderColor: "#1aab09 !important",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    margin: 0,
    display: "flex",
    alignItems: "center",
  },
  summary: {
    alignItems: "center",
  },
  expanded: {
    margin: "5px 0 !important",
  },
  disabled: {
    color: "rgba(0, 0, 0, 0.26) !important",
  },
}));
