export const API_URL = process.env.REACT_APP_API_URL;
export const MAIN_URL = process.env.REACT_APP_MAIN_URL;
export const MAIN_SYSTEM_URL = process.env.REACT_APP_MAIN_SYSTEM_URL;
export const PAYBOX_TAXES_VALUE = process.env.REACT_APP_PAYBOX_TAXES_VALUE;

export const payboxTaxesValue = Number(PAYBOX_TAXES_VALUE) || 300;
export const PAGES_ROUTE_KEY = {
  remontsPage: "/remonts",
  singleRemont: "/single-remont",
  contractJournal: "/contract-journal",
  remontInfo: "/remont-info",
  remontWorks: "/remont-works",
  remontRequests: "/remont-requests",
  remontChat: "/remont-chat",
  remontProjects: "/remont-projects",
  remontMeasure: "/remont-measure",
  remontStatuses: "/remont-statuses",
  remontProblems: "/remont-problems",
  remontAccounting: "/accounting/remont",
  mastersPayments: "/masters-payments",
  ourWorks: "/our-works",
  forContractors: "/for-contractors",
};

export const PAGES = {
  main: "/",
  login: "/login",
  register: "/register",
  forgetPassword: "/forget-password",
  profile: "/profile",
  settings: "/settings",
  residentList: "/resident-list",
  reportOkkStage: "/report-okk-stage",
  reportCostPayment: "/report-cost-payment",
  reportPartnerPayment: "/report-partner-payment",
  workChoosePage: "/work-choose-page",
  debts: "/debts",
  debtsAmount: "/debts-amount",
  externalMasterPayments: "/external-master-payments",
  repairsListPage: "/repairs-list-page",
  remontsPage: PAGES_ROUTE_KEY.remontsPage,
  remontsPageRoute: `${PAGES_ROUTE_KEY.remontsPage}/:remont_status`,
  remontsPageRouteId: ":remont_id",
  reportAddWorks: "/report-add-works",
  reportTable: "/report-table",
  reportMaterialShip: "/report-material-ship",
  problems: "/problems",
  executiveReport: "/executive-report",
  jurDateWarranty: "/jur-date-warranty",
  additionalWorksReport: "/additional-works-report",
  singleRemont: `${PAGES_ROUTE_KEY.singleRemont}/:remont_id`,
  contractJournal: PAGES_ROUTE_KEY.contractJournal,
  contractJournalType: ":type",
  worksReport: "/works-report",
  materialSearch: "/material-search",
  remontInfo: `${PAGES_ROUTE_KEY.remontInfo}/:remont_id`,
  remontWorks: `${PAGES_ROUTE_KEY.remontWorks}/:remont_id`,
  requests: PAGES_ROUTE_KEY.remontRequests,
  remontRequests: `${PAGES_ROUTE_KEY.remontRequests}/:remont_id`,
  remontChat: `${PAGES_ROUTE_KEY.remontChat}/:remont_id`,
  remontProjects: `${PAGES_ROUTE_KEY.remontProjects}/:remont_id`,
  remontList: "/remont-list",
  remontRequestList: "/remont-request-list",
  contractors: "/contractors",
  remontMeasure: `${PAGES_ROUTE_KEY.remontMeasure}/:remont_id`,
  remontStatuses: `${PAGES_ROUTE_KEY.remontStatuses}/:remont_id`,
  remontProblems: `${PAGES_ROUTE_KEY.remontProblems}/:remont_id`,
  remontAccounting: `${PAGES_ROUTE_KEY.remontAccounting}/:remont_id`,
  reportStages: "/report-stages",
  reportExpectedPayments: "/report-expected-payments",
  paymentReportsAll: "/payment-reports-all",
  prorabPaymentsReport: "/prorab-payments-report",
  reportLimit: "/report-limit",
  userSettings: "/user-settings",
  teams: "/teams",
  kanban: "/kanban",
  dispatcher: "/dispatcher",
  accountingIncomes: "/accounting-incomes",
  accountingCosts: "/accounting-costs",
  remontsAccountingReport: "/remonts-accounting-report",
  brigadirCosts: "/brigadir-costs",
  groupedMastersPayments: `${PAGES_ROUTE_KEY.mastersPayments}/group`,
  mastersPayments: PAGES_ROUTE_KEY.mastersPayments,
  mastersPaymentsRoute: `${PAGES_ROUTE_KEY.mastersPayments}/:statusCode`,
  mastersPaymentsSignedDocs: `${PAGES_ROUTE_KEY.mastersPayments}/signed-docs`,
  reportPenalty: "/report-penalty",
  reportMasterPayments: "/report-master-payments",
  forContractors: PAGES_ROUTE_KEY.forContractors,
  forContractorsForm: `${PAGES_ROUTE_KEY.forContractors}/:formType`,
  contractorInfo: "/contractor-info",
  cmrStandards: "/cmr-standards",
  ourWorks: PAGES_ROUTE_KEY.ourWorks,
  ourWorksDetail: `${PAGES_ROUTE_KEY.ourWorks}/:id`,
};

export const CREATE = "FORM:CREATE";

export const FILTER_FIELDS = {
  remontTxt: "remont",
  residentTxt: "resident",
  stageTxt: "stage",
  defextTxt: "defect",
  dateBeginTxt: "date_begin",
  dateEndTxt: "date_end",
  termTxt: "term",
  residentCostTxt: "residentCost",
  residentTechTxt: "residentTech",
};

export const FILTER_SHOW_KEYS = {
  showResidents: "resident_name",
  showRemonts: "name",
  showStage: "stage_name",
  showDefect: "defect_name",
  showCostResident: "resident_name",
};

export const LOCALSTORAGEFIELDS = {
  city: "city",
  fingerToken: "fingerToken",
};

export const COOKIES_FIELDS = {
  city: "city",
  accessToken: "accessToken",
  refreshToken: "refreshToken",
  userData: "userData",
};

export const regexOfOnlyNumbers = /^\+[0-9]+$/;

export const MODALS_NAMES = {
  okkReport: "okkReport",
  paymentHist: "paymentHist",
  masterAddOrEdit: "masterAddOrEdit",
  constractorAdd: "constractorAdd",
  materialReport: "materialReport",
  problemsModal: "problemsModal",
  singleCardModal: "singleCardModal",
  confirmModal: "confirmModal",
  deliveryModal: "deliveryModal",
  addPayments: "addPayments",
  addRecipient: "addRecipient",
  recipientForm: "recipientForm",
  plannedSum: "plannedSum",
  approveRequestModal: "approveRequestModal",
  prorabChange: "prorabChange",
  changeContractor: "changeContractor",
  executiveReportModal: "executiveReportModal",
  checkListModal: "checkListModal",
  brigadaChange: "brigadaChange",
  changeLimit: "changeLimit",
  penaltyDetails: "penaltyDetails",
  requestShipHistory: "requestShipHistory",
  requestStatusesHistory: "requestStatusesHistory",
  textModal: "textModal",
  penaltyComment: "penaltyComment",
  teamMasterDocuments: "teamMasterDocuments",
  addTeamMember: "addTeamMember",
  addTeamMaster: "addTeamMaster",
  AddExternalTeamMaster: "AddExternalTeamMaster",
  addTeamDebt: "addTeamDebt",
  addIncome: "addIncome",
  addCost: "addCost",
  addCostDebtInfo: "addCostDebtInfo",
  requestsDate: "requestsDate",
  addMasterToWorkset: "addMasterToWorkset",
  addMasterToWorkSetDispatcher: "addMasterToWorkSetDispatcher",
  okkCheck: "okkCheck",
  addEmployee: "addEmployee",
  gallery: "gallery",
};

export const DRAWER_WIDTH = 285;

export const workStatuses = {
  empty: "empty",
  full: "full",
  half: "half",
};

export const masterTypes = {
  external: "EXTERNAL",
  service: "SERVICE",
};
