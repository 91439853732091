import React from "react";
import { InsertDriveFile } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core";
import { DeleteIconWrapper } from "../blocks";
import { useCustomSnackbar } from "utils/hooks/useCustomSnackbar";

export const UploadFile = ({
  name,
  accept = "",
  onChange,
  files = [],
  label = "",
  disabled,
  onDeleteFile,
  renderUpload = null,
  isAnketa = false,
  deleteMode = false,
}) => {
  const classes = useStyles();
  const { addCloseSnack } = useCustomSnackbar();
  const hundredMb = 104857600;
  const filesSize = Object.values(files).reduce((acc, curr) => {
    acc += curr.size;
    return acc;
  }, 0);

  if (filesSize > hundredMb) {
    addCloseSnack("Превышен размер файлов");
    files = [];
  }

  const renderFileUpload = () => {
    if (renderUpload) return renderUpload();
    else
      return (
        <span className="render_file_upload">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M12 22C8.691 22 6 19.443 6 16.302V6.132C6 3.854 7.944 2 10.333 2C12.723 2 14.667 3.854 14.667 6.132L14.661 16.309C14.661 17.723 13.464 18.874 11.994 18.874C10.524 18.874 9.328 17.723 9.328 16.308L9.333 6.917C9.334 6.365 9.782 5.918 10.333 5.918H10.334C10.886 5.918 11.334 6.366 11.333 6.919L11.328 16.309C11.328 16.62 11.626 16.874 11.994 16.874C12.362 16.874 12.661 16.62 12.661 16.308L12.667 6.131C12.667 4.956 11.62 4 10.333 4C9.047 4 8 4.956 8 6.132V16.302C8 18.341 9.794 20 12 20C14.206 20 16 18.341 16 16.302V6.132C16 5.579 16.448 5.132 17 5.132C17.552 5.132 18 5.579 18 6.132V16.302C18 19.443 15.309 22 12 22Z"
              fill="#1374CF"
            />
          </svg>
          <span className="render_file_upload-text">{label}</span>
        </span>
      );
  };

  const handleFileChange = (event) => {
    onChange && onChange(event);
    event.target.value = "";
  };

  return (
    <div>
      <input
        style={{ width: 0, display: "none" }}
        name={name}
        id={"flat-button-file " + name}
        type="file"
        accept={accept}
        onChange={handleFileChange}
        disabled={disabled}
        multiple
      />
      <div className={classes.files}>
        {isAnketa
          ? null
          : Array.from(files).map((i, index) => (
              <span key={index} className={classes.fileItem}>
                <InsertDriveFile
                  style={{ width: 20, height: 20, color: "#0C4B86" }}
                />{" "}
                {i.name}
                {deleteMode && (
                  <DeleteIconWrapper
                    onClick={() => onDeleteFile && onDeleteFile(i.id, i)}
                  />
                )}
              </span>
            ))}
      </div>
      <label className="upload_file" htmlFor={"flat-button-file " + name}>
        {renderFileUpload()}
      </label>
    </div>
  );
};

const useStyles = makeStyles(() => ({
  files: {
    display: "flex",
    flexDirection: "column",
    gap: 7,
  },
  fileItem: {
    display: "flex",
    gap: 5,
    alignItems: "center",
  },
}));
