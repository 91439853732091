import moment from "moment";

export const halfHour = 1800000;
moment.locale("ru");

export const timetableMessages = {
  week: "Неделя",
  work_week: "Рабочая неделя",
  day: "День",
  month: "Месяц",
  previous: "Пред.",
  next: "След.",
  today: "Сегодня",
  agenda: "Повестка дня",
  showMore: (total) => `+${total} еще`,
};

export const timetableFormats = {
  timeGutterFormat: "HH:mm",
  eventTimeRangeFormat: ({ start, end }, culture, localizer) =>
    `${localizer.format(start, "HH:mm", culture)} - ${localizer.format(
      end,
      "HH:mm",
      culture
    )}`,
  dayRangeHeaderFormat: ({ start, end }) => {
    return (
      new Date(start).toLocaleDateString("ru") +
      " - " +
      new Date(end).toLocaleDateString("ru")
    );
  },
  weekdayFormat: (date, culture, localizer) =>
    localizer.format(date, "ddd", culture),
};

export const compareDates = (start, end) => {
  const startTime = new Date(start).getTime();
  const endTime = new Date(end).getTime();

  const difference = startTime - endTime;
  return difference === 0 || Math.abs(difference) === 1;
};
const monthNames = [
  "января",
  "февраля",
  "марта",
  "апреля",
  "мая",
  "июня",
  "июля",
  "августа",
  "сентября",
  "октября",
  "ноября",
  "декабря",
];
export const getDayInfo = (date) => {
  const parsedDate = moment(date);
  const dayName = parsedDate.format("dddd");
  const dayNameShort = parsedDate.format("ddd");
  const month = new Date(date).getMonth();
  const monthName = monthNames[month];
  const dayNumber = new Date(date).getDate();
  return {
    dayName,
    dayNameShort,
    monthName,
    dayNumber,
  };
};

export const getDayLabel = (date, short = false) => {
  const { dayNumber, dayName, monthName, dayNameShort } = getDayInfo(date);
  try {
    return `${dayNumber} ${monthName}, ${short ? dayNameShort : dayName}`;
  } catch (e) {
    return "";
  }
};
