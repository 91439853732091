export const getProblemColor = (id) => {
  switch (id) {
    case 4:
      return "#F16587";
    case 2:
      return "#81CD67";
    case 3:
      return "#F8D270";
    case 5:
      return "#F16587";
    case 1:
      return "#81CD67";
    default:
      break;
  }
};

export const DEFAULT_WALLET = "INTERPAY";

export const validFormatDate = (date) => {
  try {
    const split = date.split(".");
    if (split.length === 3) {
      const [day, month, year] = split;
      return `${year}-${month}-${day}`;
    }
    return null;
  } catch (e) {
    return null;
  }
};

export const descendingDateComparator = (a, b, orderBy) => {
  try {
    if (
      new Date(validFormatDate(b[orderBy])) <
      new Date(validFormatDate(a[orderBy]))
    ) {
      return -1;
    }
    if (
      new Date(validFormatDate(b[orderBy])) >
      new Date(validFormatDate(a[orderBy]))
    ) {
      return 1;
    }
  } catch (e) {
    return 0;
  }
};
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
export const getComparator = (order, orderBy, sortFunction) => {
  return order === "desc"
    ? (a, b) =>
        sortFunction
          ? sortFunction(a, b, orderBy)
          : descendingComparator(a, b, orderBy)
    : (a, b) =>
        sortFunction
          ? -sortFunction(a, b, orderBy)
          : -descendingComparator(a, b, orderBy);
};

export const stableSort = (array, order, orderBy, sortFunction) => {
  const comparator = getComparator(order, orderBy, sortFunction);
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};
