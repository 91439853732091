import React, { useState } from "react";
import { Box, Checkbox, ClickAwayListener } from "@material-ui/core";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { changeStatus } from "./api";
import { useDispatch } from "react-redux";
import { setStatusesList } from "../../../services/redux/actions/remonts-actions";
import { useCustomSnackbar } from "../../../utils/hooks/useCustomSnackbar";
import {
  CustomTableCell,
  CustomTableRow,
} from "../../../components/common/CustomTable/CustomTable";

const StatusesListItem = ({
  fio,
  is_checked,
  mark_type_code,
  mark_type_name_without_tag,
  date_mark,
  remont_id,
  mark_desc,
  is_edit,
}) => {
  const classes = useStyles();
  const { addCloseSnack, addDefaultSnack } = useCustomSnackbar();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const handleChange = () => {
    changeStatus({ remont_id, mark_type_code })
      .then((res) => {
        dispatch(setStatusesList(res?.data));
        addDefaultSnack("Успешно");
      })
      .catch((err) => {
        addCloseSnack(err.response.data.error);
      });
  };

  const handleClickAway = () => {
    setOpen(false);
  };

  const handleClick = () => {
    setOpen(!open);
  };
  return (
    <CustomTableRow>
      <CustomTableCell
        className={clsx(classes.parent)}
        classes={{ root: classes.root }}
      >
        <ClickAwayListener onClickAway={handleClickAway}>
          <HelpOutlineIcon className={classes.icon} onClick={handleClick} />
        </ClickAwayListener>
      </CustomTableCell>
      <CustomTableCell classes={{ root: classes.root }}>
        <Checkbox
          disabled={!Boolean(is_edit)}
          checked={Boolean(is_checked)}
          className={classes.checkbox}
          inputProps={{ "aria-label": "secondary checkbox" }}
          onChange={handleChange}
        />
      </CustomTableCell>
      <CustomTableCell
        className={clsx(classes.parent)}
        classes={{ root: classes.root }}
      >
        {mark_type_name_without_tag}
        {!open ? null : (
          <Box className={classes.descr}>
            {mark_desc ? mark_desc : "Описание отсутствует"}
          </Box>
        )}
      </CustomTableCell>
      <CustomTableCell classes={{ root: classes.root }}>
        {date_mark}
      </CustomTableCell>
      <CustomTableCell classes={{ root: classes.root }}>{fio}</CustomTableCell>
    </CustomTableRow>
  );
};

export default React.memo(StatusesListItem);

const useStyles = makeStyles({
  icon: {
    color: "#659DF1",
    cursor: "pointer",
    width: 24,
    height: 24,
    display: "inline-block",
    marginTop: 5,
  },
  root: {
    padding: 5,
  },
  checkbox: {
    height: 20,
    color: "#659DF1",
    "&.MuiCheckbox-colorSecondary.Mui-checked": {
      color: "#659DF1",
    },
    "&.MuiCheckbox-colorSecondary.Mui-disabled": {
      color: "rgba(0, 0, 0, 0.26) !important",
    },
  },
  parent: {
    position: "relative",
  },
  descr: {
    position: "absolute",
    backgroundColor: "#fff",
    zIndex: 10,
    minWidth: 100,
    top: "50%",
    transform: "translateY(-50%)",
    left: "-63px",
    padding: "5px 10px",
    boxShadow: "0px 0px 20px rgba(108, 108, 108, 0.2)",
  },
});
