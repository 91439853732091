import React, { useState } from "react";
import ruLocale from "date-fns/locale/ru";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import AdapterDateFns from "@date-io/date-fns";
import { IconButton } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import { getLocaleDate, getValidDate } from "../../../utils";
import { makeStyles } from "@material-ui/core/styles";
import { validFormatDate } from "services";

const DEFAULT_WIDTH = 170;

const useStyles = makeStyles({
  date: {
    "& .MuiIconButton-root": {
      padding: "2px !important",
    },
  },
  control: {
    "& .MuiFormHelperText-filled": {
      position: "absolute",
      bottom: "-20px",
    },
  },
});

const getInitialDate = (value, alternativeFormat) => {
  if (!value) return null;
  return alternativeFormat ? value : validFormatDate(value);
};

const isValidDate = (d) => d instanceof Date && !isNaN(d);

export const CustomDatePicker = ({
  name = "",
  label = "",
  value = "",
  className = "",
  alternativeFormat = false,
  variant = "standard",
  placeholder = "",
  absoluteMessage = false,
  shrink,
  onChange,
  disableFuture = false,
  disablePast = false,
  disabled = false,
  fullWidth,
  width = DEFAULT_WIDTH,
}) => {
  const classes = useStyles();
  const [valueState, setValueState] = useState(
    getInitialDate(value, alternativeFormat)
  );
  const props = {
    name,
    label,
    variant,
    placeholder,
    disablePast,
    shrink,
    fullWidth,
    disabled,
    sx: {
      width: fullWidth ? "100%" : width,
    },
  };
  const handleChange = (e, value) => {
    const event = {
      target: { name, value: getLocaleDate(e) },
    };
    if (!value) {
      if (valueState === null) return;
      event.target.value = "";
      setValueState(null);
      return onChange && onChange(event);
    }
    if (!isValidDate(e)) return;
    setValueState(getValidDate(e));
    return onChange && onChange(event);
  };

  const onClear = () => {
    setValueState && setValueState(null);
    return onChange && onChange({ target: { name, value: "" } });
  };

  return (
    <MuiPickersUtilsProvider utils={AdapterDateFns} locale={ruLocale}>
      <KeyboardDatePicker
        name={name}
        format="dd.MM.yyyy"
        className={`${className} ${absoluteMessage ? classes.control : ""}`}
        value={valueState}
        disableToolbar
        disableFuture={disableFuture}
        autoOk
        KeyboardButtonProps={{
          "aria-label": "change date",
        }}
        minDate={new Date("0000-01-01")}
        maxDate={new Date("9999-12-31")}
        fullWidth
        maxDateMessage={"Превышена макс. дата"}
        minDateMessage={"Достигнута мин. дата"}
        invalidDateMessage="Некорректная дата"
        invalidLabel="Некорректная дата"
        inputProps={{
          placeholder: props.placeholder || "Введите дату",
        }}
        onChange={handleChange}
        InputProps={{
          className: classes.date,
          endAdornment: (
            <IconButton onClick={onClear}>
              <ClearIcon />
            </IconButton>
          ),
        }}
        InputAdornmentProps={{
          position: "start",
        }}
        {...props}
      />
    </MuiPickersUtilsProvider>
  );
};
