import { TextareaAutosize } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import { CustomImage } from "components/common/blocks/ImgWrapper";
import { UploadFile } from "components/common/form/UploadFile";
import { NotFound } from "components/common/text";
import { okkCheck, refactorWorkSetMediaData } from "pages/dispatcher/services";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { showPopUp } from "services/redux/actions/app-actions";
import { useCustomSnackbar } from "utils/hooks/useCustomSnackbar";
import { CButton } from "../../../common/buttons";
import { format } from "date-fns";
import { MODALS_NAMES } from "../../../../constants";
import clsx from "clsx";
import { CheckIconWrapper } from "components/common/blocks";
import WarningIcon from "@material-ui/icons/Warning";
import { CustomAccordion } from "components/common/blocks/CustomAccordion";
import { getMediaTypeByExtension } from "utils";
import PlayIcon from "@material-ui/icons/PlayCircleOutline";

export const OkkCheck = ({ data }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { addDefaultSnack } = useCustomSnackbar();
  const [isLoading, setIsLoading] = useState({
    is_accept: null,
    loading: false,
  });
  const [formData, setFormData] = useState(
    data?.formData ? data.formData : { comment: "", files: [] }
  );
  const [mediaData, setMediaData] = useState([]);
  const {
    work_set_name,
    work_set_id,
    team_master_fio,
    media,
    remont_id,
    team_master_id,
    actions,
    onSubmit,
  } = data;

  useEffect(() => {
    const refactoredMedia = refactorWorkSetMediaData(media);
    setMediaData(refactoredMedia || []);
  }, [media]);

  const handleSubmit = async (is_accept) => {
    if (isLoading.loading) return;
    const body = new FormData();
    body.append("work_set_id", work_set_id);
    body.append("remont_id", remont_id);
    body.append("date_submitted", format(new Date(), "dd.MM.yyyy HH:mm:ss"));
    body.append("comment", formData.comment || "");
    body.append("is_accept", String(is_accept));
    body.append("team_master_id", String(team_master_id));
    if (formData?.files?.length) {
      formData.files.forEach((file) => {
        body.append("media_list", file);
      });
    }
    setIsLoading({ is_accept, loading: true });
    const res = await okkCheck(remont_id, body);
    setIsLoading({ is_accept: null, loading: false });
    if (!res) return;
    addDefaultSnack("Успешно");
    onSubmit && onSubmit(res);
    dispatch(showPopUp(false));
  };

  const onChange = (key, value) => {
    if (key === "files" && !value?.length) return;
    let newFiles = [...value].filter(
      (file) => !formData.files.find((item) => item.name === file.name)
    );

    setFormData((prev) => ({
      ...prev,
      [key]: key === "files" ? [...prev.files, ...newFiles] : value,
    }));
  };

  const onImageClick = (idx, images) => {
    dispatch(
      showPopUp(true, MODALS_NAMES.gallery, {
        activeIndex: idx,
        images: images?.map((item) => item.url),
        isFullUrl: true,
        handleClose: () =>
          dispatch(
            showPopUp(true, MODALS_NAMES.okkCheck, { ...data, formData })
          ),
      })
    );
  };

  const btnDisabled = useMemo(() => {
    if (!formData.comment && !formData?.length)
      return { reject: true, accept: false };
    return { reject: false, accept: true };
  }, [formData]);

  return (
    <div className={classes.wrapper}>
      <h2 className={classes.title}>{`${work_set_name}${
        team_master_fio ? ` (${team_master_fio})` : ""
      }`}</h2>

      <div>
        {mediaData?.length ? (
          mediaData.map((item, i) => {
            return (
              <CustomAccordion
                checkLabelClick={false}
                defaultExpanded={media.length - 1 === i}
                key={i}
                renderData={() => (
                  <h3 className={classes.mediaTitle}>
                    {item.master_work_set_media_type_name || ""}
                    {!!item.date && <span> ({item.date})</span>}
                  </h3>
                )}
              >
                <div>
                  <div className={classes.imagesWrapper}>
                    {item.files?.map((file, i) => {
                      return (
                        <div
                          key={i}
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          {getMediaTypeByExtension(file.url) === "video" ? (
                            <div
                              className={classes.img}
                              style={{ position: "relative" }}
                              onClick={() => onImageClick(i, item.files)}
                            >
                              <video controls={false} className={classes.img}>
                                <source
                                  src={file.url}
                                  type="video/mp4"
                                ></source>{" "}
                              </video>
                              <PlayIcon
                                style={{
                                  position: "absolute",
                                  top: "calc(50% - 20px)",
                                  left: "calc(50% - 20px)",
                                  color: "#0C4B86",
                                  fontSize: 40,
                                }}
                              />
                            </div>
                          ) : (
                            <CustomImage
                              className={classes.img}
                              isStaticImg
                              src={file.url}
                              onClick={() => onImageClick(i, item.files)}
                            />
                          )}
                          {file.desc && <span>{file.desc}</span>}
                        </div>
                      );
                    })}
                  </div>
                  {!!item.comment && <div>Комментарий: {item.comment}</div>}
                </div>
              </CustomAccordion>
            );
          })
        ) : (
          <NotFound />
        )}
      </div>
      {actions && (
        <div className={classes.footer}>
          <TextareaAutosize
            placeholder="Введите замечания"
            className={classes.textarea}
            minRows={5}
            name="comment"
            value={formData?.comment || ""}
            onChange={(e) => onChange("comment", e?.target?.value)}
          />
          <UploadFile
            name="work_files"
            onChange={(e) => onChange("files", e.target.files)}
            required="required"
            files={formData.files}
            deleteMode
            onDeleteFile={(_, obj) =>
              setFormData((prev) => ({
                ...prev,
                files: prev.files?.filter((item) => item.name !== obj?.name),
              }))
            }
            renderUpload={() => (
              <span className={classes.attach}>
                <AttachFileIcon style={{ color: "green" }} />
                Загрузить файлы
              </span>
            )}
          />
          <div className={classes.flex}>
            <CButton
              className={clsx(classes.submitBtn, classes.rejectBtn)}
              confirmMode
              loading={isLoading.is_accept === 0 && isLoading.loading}
              onClick={() => handleSubmit(0)}
              disabled={btnDisabled.reject}
              confirmTitle="Вы действительно хотите отправить работу на исправление?"
            >
              Оставить замечания{" "}
              <WarningIcon
                style={{ fontSize: 18, color: "#fff", marginLeft: 3 }}
              />
            </CButton>
            <CButton
              confirmMode
              className={classes.submitBtn}
              loading={isLoading.is_accept === 1 && isLoading.loading}
              onClick={() => handleSubmit(1)}
              disabled={btnDisabled.accept}
              confirmTitle="Вы действительно хотите принять работу?"
            >
              Принять работу{" "}
              <CheckIconWrapper style={{ color: "#fff", marginLeft: 3 }} />
            </CButton>
          </div>
        </div>
      )}
    </div>
  );
};
const useStyles = makeStyles((theme) => ({
  wrapper: {
    minWidth: 400,
    [theme.breakpoints.down(576)]: {
      minWidth: "100%",
    },
  },
  title: {
    margin: 0,
    marginBottom: 10,
    marginTop: 10,
  },
  workset: {
    margin: 0,
  },
  submitBtn: {
    width: "50%",
    height: 45,
    minWidth: 250,
    fontWeight: 700,
    lineHeight: "16px",
    backgroundColor: "#13b221",
    "&:hover": {
      backgroundColor: "#13b221",
    },
    [theme.breakpoints.down(576)]: {
      minWidth: "100%",
      width: "100%",
    },
  },
  rejectBtn: {
    backgroundColor: "rgb(255, 140, 0)",
    "&:hover": {
      backgroundColor: "rgb(255, 140, 0)",
    },
  },
  mediaTitle: {
    margin: 0,
    "& > span": {
      color: "#5f5f5f",
      fontSize: 13,
    },
  },
  img: {
    width: 95,
    height: 144,
    borderRadius: "10px",
    objectFit: "cover",
    cursor: "pointer",
  },
  imagesWrapper: {
    display: "flex",
    alignItems: "center",
    gap: 10,
    flexWrap: "wrap",
  },
  flex: {
    display: "flex",
    flexDirection: "column",
    gap: 10,
    marginTop: 20,
    alignItems: "center",
  },
  footer: {
    marginTop: 25,
  },
  textarea: {
    width: "100%",
    borderRadius: 5,
    padding: 10,
    outline: "none",
  },
  attach: {
    display: "flex",
    alignItems: "center",
    gap: 5,
    marginTop: 10,
    cursor: "pointer",
  },
}));
