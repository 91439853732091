import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@material-ui/core";
import React, { useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { NotFound } from "../text";
import clsx from "clsx";
import { CustomLoader } from "../CustomLoader";
import { WrapperScrollbar } from "../WrapperScrollbar";
import { UpdateTableHeader } from "../blocks";
import { getTableMaxHeight } from "../../../utils";
import { stableSort } from "services";

export const CustomTable = ({
  headItems = [],
  isEmpty = false,
  isFetching = false,
  rowsData = null,
  withMaxHeight = true,
  maxHeight = null,
  wrapperClassName = "",
  containerClassName = "",
  noDataText = "Ничего не найдено",
  children,
  headCellClassName = "",
  headerRender = null,
  backgroundColor = null,
  stickyHeader = false,
  updateDataReq,
  updateBtnSide = "",
  highlight = false,
  borderSeparate = false,
  borderSpacing = 0,
}) => {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState(null);
  const [sortFunction, setSortFunction] = React.useState({ act: null });
  const classes = useStyles({
    maxHeight: stickyHeader ? getTableMaxHeight(maxHeight) : "auto",
  });

  const handleRequestSort = (property, sortFunction) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    setSortFunction({ act: sortFunction });
  };

  const sortedData = useMemo(() => {
    if (!rowsData?.length) return [];
    return stableSort(rowsData, order, orderBy, sortFunction.act);
  }, [rowsData, order, orderBy, sortFunction.act]);

  return (
    <div className={clsx(wrapperClassName, containerClassName)}>
      <WrapperScrollbar
        className={clsx(classes.container, containerClassName, {
          [classes.tableContainer]: !!withMaxHeight && !isEmpty,
        })}
      >
        <Table
          aria-label="simple table"
          stickyHeader={stickyHeader}
          classes={{ root: clsx({ [classes.table]: highlight }) }}
          style={{
            borderCollapse: borderSeparate ? "separate" : "collapse",
            borderSpacing: borderSpacing || 0,
            backgroundColor: backgroundColor ? backgroundColor : "unset",
          }}
        >
          <TableHead className={classes.headRow}>
            {!!headerRender &&
              headerRender({ order, orderBy, handleRequestSort })}
            {!!updateDataReq && (
              <UpdateTableHeader
                side={updateBtnSide}
                onClick={updateDataReq}
                isFetching={isFetching}
              />
            )}
            {!!headItems?.length && (
              <TableRow>
                {headItems?.map((item, i) => {
                  return (
                    <CustomTableHeadCell
                      key={i}
                      {...item}
                      headerRender={headerRender}
                      order={order}
                      orderBy={orderBy}
                      className={clsx(headCellClassName, item?.className || "")}
                      handleRequestSort={handleRequestSort}
                    />
                  );
                })}
              </TableRow>
            )}
          </TableHead>
          {!isFetching &&
            (children ? (
              <TableBody>
                {typeof children === "function"
                  ? children({ sortedData })
                  : children}
              </TableBody>
            ) : (
              <>
                {rowsData?.map((item, i) => {
                  return (
                    <CustomTableRow key={i}>
                      {headItems.map((headItem, idx) => {
                        return (
                          <CustomTableCell
                            align={headItem.align || "left"}
                            key={idx}
                          >
                            {headItem.format
                              ? headItem.format(item[headItem.key])
                              : headItem.renderContent
                              ? headItem.renderContent(item)
                              : item[headItem.key]}
                          </CustomTableCell>
                        );
                      })}
                    </CustomTableRow>
                  );
                })}
              </>
            ))}
        </Table>
        {isFetching ? (
          <CustomLoader />
        ) : (
          isEmpty && <NotFound>{noDataText}</NotFound>
        )}
      </WrapperScrollbar>
    </div>
  );
};

export const CustomTableRow = ({
  children,
  className,
  fixedDirection = "bottom",
  fixed = false,
  isBinaryDiv = true,
  highlight = false,
  isNew = false,
  ...other
}) => {
  const classes = useStyles();
  return (
    <TableRow
      className={clsx(
        classes.row,
        { [classes.new_row]: !!isNew },
        {
          [`${classes.fixedRow} ${classes[`fixed_${fixedDirection}`]}`]: fixed,
        },
        { [classes.highlightRow]: highlight },
        { [classes.classicDiv]: isBinaryDiv },
        { [classes.alternativeDiv]: isBinaryDiv === false },
        className
      )}
      {...other}
    >
      {children}
    </TableRow>
  );
};

export const CustomTableCell = ({
  children,
  className,
  openTab,
  align,
  fixed = false,
  ...other
}) => {
  const classes = useStyles();
  return (
    <TableCell
      align={align || "left"}
      className={clsx(
        classes.border,
        { [classes.fixedCell]: fixed },
        { [classes.borderless]: openTab === false },
        className
      )}
      {...other}
    >
      {children}
    </TableCell>
  );
};

export const CustomTableHeadCell = ({
  headerRender,
  className,
  style,
  align,
  render,
  label,
  orderBy,
  order,
  border = false,
  sort = false,
  children = null,
  backgroundColor,
  name,
  sortFunction,
  handleRequestSort,
  ...props
}) => {
  const classes = useStyles({ backgroundColor });
  return (
    <TableCell
      classes={{
        stickyHeader: !!headerRender ? classes.stickyHeader : "",
      }}
      className={clsx(classes.headCell, className, {
        [classes.headCellBorder]: border,
      })}
      style={style || {}}
      align={align || "left"}
      {...props}
    >
      {sort ? (
        <TableSortLabel
          active={orderBy === name}
          direction={orderBy === name ? order : "asc"}
          onClick={() =>
            handleRequestSort && handleRequestSort(name, sortFunction)
          }
        >
          <HeadItemText>{label}</HeadItemText>
          <span className={classes.visuallyHidden}>
            {order === "desc" ? "sorted descending" : "sorted ascending"}
          </span>
        </TableSortLabel>
      ) : !!render ? (
        <div className={classes.headText}>{render()}</div>
      ) : (
        !!label && <HeadItemText>{label || ""}</HeadItemText>
      )}
      {children}
    </TableCell>
  );
};

export const HeadItemText = ({ children }) => (
  <span className={useStyles().headText}>{children}</span>
);

const useStyles = makeStyles({
  headCell: {
    padding: "5px !important",
    borderLeft: "1px solid #F0F2F6",
    backgroundColor: (props) =>
      `${props.backgroundColor || "#ECEEFD"} !important`,
    lineHeight: "16px",
  },
  headCellBorder: {
    border: "1px solid rgb(194, 195, 207) !important",
  },
  table: {
    borderCollapse: "collapse",
  },
  headText: {
    color: "#696C7C",
    fontSize: 12,
    fontWeight: 600,
    lineHeight: "16px",
  },
  tableContainer: {
    maxHeight: (props) => `${props.maxHeight}px`,
  },
  container: {
    width: "100%",
    position: "relative",
    overflowY: "auto",
    overflowX: "auto !important",
    boxShadow:
      "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
    borderRadius: "0.5rem",
    color: "rgba(0, 0, 0, 0.87)",
    transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    backgroundColor: "#fff",
  },
  headRow: {
    backgroundColor: "#ECEEFD",
  },
  root: {
    marginBottom: 15,
  },
  border: {
    border: "1px solid #F0F2F6",
    padding: "2px 5px",
  },
  borderless: {
    padding: "0 5px",
  },
  cursor: {
    cursor: "pointer",
  },
  row: {
    "&:hover": {
      backgroundColor: "#ececec",
    },
  },
  classicDiv: {
    "&:nth-child(odd)": {
      backgroundColor: "#f9f9f9",
      "&:hover": {
        backgroundColor: "#ececec",
      },
    },
    "&:nth-child(even)": {
      backgroundColor: "#fff",
      "&:hover": {
        backgroundColor: "#ececec",
      },
    },
  },
  alternativeDiv: {
    backgroundColor: "#ffffff",
    "&:nth-child(4n - 1)": {
      backgroundColor: "#f9f9f9",
      "&:hover": {
        backgroundColor: "#ececec",
      },
    },
  },
  fixedRow: {
    position: "sticky",
    backgroundColor: "#ECEEFD !important",
    "&:hover": {
      backgroundColor: "#ECEEFD !important",
    },
  },
  fixed_top: {
    top: 0,
  },
  fixed_bottom: {
    bottom: 0,
  },
  fixedCell: {
    fontWeight: "700 !important",
  },
  highlightRow: {
    borderBottom: "2px solid #ccc !important",
  },
  stickyHeader: {
    top: "45px",
  },
  new_row: {
    backgroundColor: "#a6e081 !important",
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
});
