import { descendingDateComparator } from "services";
import { dispatcherAPI } from "./api";
import { API_URL } from "../../../constants";

export const getDispatcherResidents = async () => {
  try {
    const res = await dispatcherAPI.getResidents();
    return res?.data;
  } catch (e) {}
};

export const getDispatcherMasters = async (body) => {
  try {
    const res = await dispatcherAPI.getMasters(body);
    return res?.data;
  } catch (e) {}
};
export const getDispatcherData = async (filters) => {
  try {
    const res = await dispatcherAPI.getList(filters);
    return res?.data;
  } catch (e) {}
};
export const getDispatcherRemontDetails = async (remontId) => {
  try {
    const res = await dispatcherAPI.getRemontDetails(remontId);
    return { specialities: res?.data, remont_info: res?.remont_info };
  } catch (e) {}
};
export const getDispatcherMasterBusyness = async (body) => {
  try {
    const res = await dispatcherAPI.getMasterBusyness(body);
    return res?.data;
  } catch (e) {}
};
export const assignMasterToWorkSet = async (body) => {
  try {
    const res = await dispatcherAPI.assignMasterToWorkSet(body);
    return { specialities: res?.data, remont_info: res?.remont_info };
  } catch (e) {}
};
export const getOkkData = async (remontId) => {
  try {
    return await dispatcherAPI.getOkkData(remontId);
  } catch (e) {}
};
export const okkCheck = async (remontId, body) => {
  try {
    return await dispatcherAPI.okkCheck(remontId, body);
  } catch (e) {}
};

export const dispatcherHeadItems = [
  [
    {
      label: "ID Ремонта",
      align: "center",
      rowspan: "2",
      style: { borderRadius: " 8px 0 0 0" },
    },
    {
      label: "Дата окончания",
      align: "center",
      sort: true,
      rowspan: "2",
      sortFunction: descendingDateComparator,
      name: "jur_remont_date_end",
    },
    { label: "ЖК", rowspan: "2", align: "left" },
    // { label: "Готовность ЖК", align: "center" },
    { label: "Назначение мастеров", rowspan: "2", align: "center" },
    { label: "Проверка ОКК", rowspan: "2", align: "center" },
    { label: "Оплаты", rowspan: "2", align: "center" },
    { label: "Не начатые", rowspan: "2", align: "right" },
    { label: "В работе", rowspan: "2", align: "right" },
    { label: "Отстающие", colspan: "2", align: "center" },
    {
      label: "Завершенные",
      align: "right",
      rowspan: "2",
      style: { borderRadius: "0 8px 0 0" },
    },
  ],
  [
    {
      label: "Не начат",
      align: "right",
    },
    {
      label: "Начат",
      align: "right",
    },
  ],
];

export const getFirstDayOfMonth = (dateString = "") => {
  if (!dateString) return "";
  try {
    const date = new Date(dateString);
    const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);

    const day = String(firstDay.getDate()).padStart(2, "0");
    const month = String(firstDay.getMonth() + 1).padStart(2, "0");
    const year = firstDay.getFullYear();

    return `${day}.${month}.${year}`;
  } catch (e) {
    return "";
  }
};

export const okkCheckBtnColors = {
  WAITING: {
    color: "#0C4B86",
    textColor: "rgb(9, 49, 85)",
    label: "Ожидает проверки",
    secondColor: "rgb(118, 175, 227)",
  },
  REJECTED: {
    color: "rgb(255, 140, 0)",
    textColor: "rgb(133, 82, 20)",
    label: "На исправлении",
    secondColor: "rgb(255, 228, 195)",
  },
  CHECKED: {
    color: "#13b221",
    textColor: "rgb(8, 85, 15)",
    label: "Завершено",
    secondColor: "rgb(158, 207, 162)",
  },
};

export const getChipColor = (done, all) => {
  try {
    if (!done && !all) return "#ccc";
    if (done === 0) return "#f13b3b";
    if (done === all) return "rgb(0, 163, 1)";
    if (done > 0) return "rgb(255, 130, 28)";
  } catch (e) {}
  return "#fbbc04";
};
export const refactorWorkSetMediaData = (data) => {
  return (
    data?.map((item, idx) => {
      let files = item?.media_url_list?.map((fileUrl, i) => {
        return {
          url: item.isOfflineData ? fileUrl : `${API_URL}${fileUrl}`,
          id: i,
          checked: false,
        };
      });
      if (item.rooms?.length)
        item.rooms.forEach((room) => {
          if (room?.media_url_list?.length) {
            room.media_url_list.forEach((roomFileUrl, i) => {
              const roomFile = {
                url: item.isOfflineData
                  ? roomFileUrl
                  : `${API_URL}${roomFileUrl}`,
                id: i + (item?.media_url_list?.length || 0),
                checked: false,
                desc: room.room_name,
              };
              files.push(roomFile);
            });
          }
        });
      return {
        ...item,
        id: `${idx}-${item.date}`,
        files,
      };
    }) || []
  );
};
