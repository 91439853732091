import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import { CButton } from "../../buttons";
import clsx from "clsx";

export const CustomConfirm = ({ children, onSubmit, loading, title }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div>
      <div onClick={handleClick}>{children}</div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <div className={classes.wrapper}>
          <Typography className={classes.typography}>
            {title || "Подтвердите действие"}
          </Typography>
          <div className={classes.btns}>
            <CButton
              className={clsx(classes.btn, classes.cancel)}
              mode={"auto"}
              onClick={handleClose}
            >
              Отмена
            </CButton>
            <CButton
              className={clsx(classes.btn, classes.submit)}
              mode={"auto"}
              loading={loading}
              onClick={onSubmit}
            >
              Подтверить
            </CButton>
          </div>
        </div>
      </Popover>
    </div>
  );
};

const useStyles = makeStyles(() => ({
  wrapper: {
    padding: 10,
  },
  typography: {
    textAlign: "center",
    fontSize: 14,
    fontWeight: "bold",
    lineHeight: "16px",
    marginBottom: 10,
    maxWidth: "180px",
  },
  btns: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: 10,
  },
  btn: {
    padding: 5,
    height: "auto",
    fontSize: 12,
  },
  cancel: {
    background: "#a80c0c",
    "&:hover": {
      background: "#c02020",
    },
  },
  submit: {
    background: "#6ac54e",
    "&:hover": {
      background: "#4a9d2f",
    },
  },
}));
