import instance from "../../../services/api";

export const authAPI = {
  async login(body) {
    return instance(false, null, { throwError: true })
      .post("/auth/login/", body)
      .then((res) => res?.data);
  },
  async register(body) {
    return instance(false)
      .post("/auth/signup/", body)
      .then((res) => res?.data);
  },
  async forgetPassword(body) {
    return instance(false)
      .post("/auth/password_reset/", body)
      .then((res) => res?.data);
  },
};
