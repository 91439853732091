import React, { useEffect, useState } from "react";
import { PageTitle } from "../../components/common/pageTitle";
import { Box, LinearProgress, makeStyles } from "@material-ui/core";
import { CheckRemontPermission } from "../remonts/CheckRemontPermission";
import {
  answerToRemontRequest,
  contractorRequestsHeadItems,
  getRemontRequestList,
} from "./services";
import { NotFound } from "components/common/text";
import { CButton } from "components/common/buttons";
import clsx from "clsx";
import { CustomConfirm } from "components/common/blocks/CustomConfirm";
import { useCustomSnackbar } from "utils/hooks/useCustomSnackbar";
import {
  CustomTable,
  CustomTableCell,
  CustomTableRow,
} from "components/common/CustomTable/CustomTable";
import { Clickable } from "components/common/Clickable";
import DownloadIcon from "@material-ui/icons/CloudDownloadOutlined";
import { MAIN_URL } from "../../constants";
import { useDownloadFile } from "pages/remonts/RemontInfo/Gpr/useDownloadFile";

export const RemontRequestList = () => {
  const classes = useStyles();
  const { addDefaultSnack } = useCustomSnackbar();
  const { downloadFile, downloading } = useDownloadFile();
  const [isFetching, setIsFetching] = useState(false);
  const [loading, setLoading] = useState({});
  const [data, setData] = useState([]);

  useEffect(() => {
    const getData = async () => {
      setIsFetching(true);
      const res = await getRemontRequestList();
      setIsFetching(false);
      setData(res || []);
    };
    getData();
  }, []);

  const handleAnswer = async (contractor_request_id, isAccepted) => {
    setLoading((prev) => ({ ...prev, [contractor_request_id]: true }));
    const res = await answerToRemontRequest({
      contractor_request_id,
      is_accepted: isAccepted,
    });
    setLoading((prev) => ({ ...prev, [contractor_request_id]: false }));
    if (!res) return;
    setData(res);
    addDefaultSnack("Успешно");
  };

  const downloadTK = async (project_remont_id) => {
    downloadFile(
      `/partner/projects/${project_remont_id}/`,
      `ТК - ${project_remont_id}`,
      {
        responseType: "arraybuffer",
      },
      "pdf",
      "get",
      true,
      false
    );
  };

  return (
    <CheckRemontPermission>
      <PageTitle title="Заявки на ремонт" />
      <Box className={classes.content}>
        {isFetching ? (
          <LinearProgress variant="indeterminate" />
        ) : data?.length ? (
          <CustomTable
            headItems={contractorRequestsHeadItems}
            isEmpty={!data?.length}
            isFetching={isFetching}
          >
            {data?.map((item, i) => {
              return (
                <CustomTableRow key={i} className={classes.row}>
                  <CustomTableCell align="left">
                    {i + 1}. {item.resident_name}
                  </CustomTableCell>
                  <CustomTableCell align="center">
                    {item.client_request_id}
                  </CustomTableCell>
                  <CustomTableCell align="center">
                    {!!item.project_remont_id && (
                      <Clickable
                        className={classes.link}
                        loading={downloading}
                        onClick={() => downloadTK(item.project_remont_id)}
                      >
                        Скачать ТК
                        <DownloadIcon />
                      </Clickable>
                    )}
                  </CustomTableCell>
                  <CustomTableCell align="center">
                    {!!item.project_remont_url && (
                      <Clickable
                        link
                        href={`${MAIN_URL}${item?.project_remont_url}`}
                        className={classes.link}
                      >
                        Скачать проект
                        <DownloadIcon />
                      </Clickable>
                    )}
                    {!!item.project_remont_comment && (
                      <div className={classes.comment}>
                        Комментарий: {item.project_remont_comment}
                      </div>
                    )}
                  </CustomTableCell>
                  <CustomTableCell align="center">
                    {item.is_accepted === null ? (
                      <div className={classes.btns}>
                        <CustomConfirm
                          title={"Вы действительно хотите отклонить заявку?"}
                          loading={!!loading[item.contractor_request_id]}
                          onSubmit={() =>
                            handleAnswer(item.contractor_request_id, false)
                          }
                        >
                          <CButton
                            className={clsx(classes.btn, classes.cancel)}
                            mode={"auto"}
                          >
                            Отклонить
                          </CButton>
                        </CustomConfirm>
                        <CustomConfirm
                          title={"Вы действительно хотите принять заявку?"}
                          loading={!!loading[item.contractor_request_id]}
                          onSubmit={() =>
                            handleAnswer(item.contractor_request_id, true)
                          }
                        >
                          <CButton
                            className={clsx(classes.btn, classes.submit)}
                            mode={"auto"}
                          >
                            Принять
                          </CButton>
                        </CustomConfirm>
                      </div>
                    ) : (
                      <div
                        className={clsx(classes.answer, {
                          [classes.accepted]: item.is_accepted,
                          [classes.rejected]: !item.is_accepted,
                        })}
                      >
                        {item.is_accepted
                          ? "Заявка принята"
                          : "Заявка отклонена"}
                      </div>
                    )}
                  </CustomTableCell>
                </CustomTableRow>
              );
            })}
          </CustomTable>
        ) : (
          <NotFound />
        )}
      </Box>
    </CheckRemontPermission>
  );
};

const useStyles = makeStyles(() => ({
  content: {
    display: "flex",
    flexDirection: "column",
    gap: 15,
    padding: 20,
  },
  contentItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: 10,
    border: "1px solid #e3e3e3",
    borderRadius: 12,
    padding: 15,
  },
  btns: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: 10,
  },
  btn: {
    padding: 5,
    height: "auto",
    fontSize: 12,
  },
  cancel: {
    background: "#a80c0c",
    "&:hover": {
      background: "#c02020",
    },
  },
  submit: {
    background: "#6ac54e",
    "&:hover": {
      background: "#4a9d2f",
    },
  },
  answer: {
    fontSize: 15,
    fontWeight: 700,
  },
  accepted: {
    color: "#2aa32a",
  },
  rejected: {
    color: "#df3636",
  },
  link: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: 5,
  },
  comment: {
    marginTop: 5,
    color: "#404040",
  },
  row: {
    "& td": {
      height: 40,
    },
  },
}));
